<script>
  import Alert from "@/Components/Elements/Alert.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Layout from "@/Layouts/App.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
</script>

<Seo title="Wallet" />

<Layout>
  <div class="content">
    <div class="header">
      <div>
        <h1>Transaction</h1>
        <Breadcrumb crumbs={[
            { title: 'Menu', url: '/' },
            { title: 'Wallet', url: '/app/wallet' },
            { title: 'Transaction' },
        ]} />
      </div>
    </div>

    <div class="main">
      {#if $page.props.error}
        <Alert type="error" title={$page.props.error} />
      {/if}

      {#if $page.props.transaction}
        <h2>Transfer details</h2>
        <div class="table-wrap">
          <table>
            <tr>
              <th>Withdrawn</th>
              <td>{$page.props.transaction?.amount_paid} {$page.props.transaction?.source_currency}</td>
            </tr>
            <tr>
              <th>Transfer date</th>
              <td>{$page.props.transaction?.payment_date}
<!--                <br /><span>Takes 0-3 business days to arrive</span> -->
              </td>
            </tr>
            <tr>
              <th>Transfer method</th>
              <td>{$page.props.payment?.payment_method ?? '-'}</td>
            </tr>
            <tr>
              <th>Transfer fee</th>
              <td>{$page.props.transaction?.fee_amount} {$page.props.transaction?.fee_currency}</td>
            </tr>
            <tr>
              <th>Pitchfire fee</th>
              <td>0 USD
                <br /><span>We do not take a fee at this moment in time</span>
              </td>
            </tr>
            <tr>
              <th>You receive</th>
              <td>{$page.props.transaction?.amount_received}  {$page.props.transaction?.payment_currency}</td>
            </tr>
            <tr>
              <th>Reference</th>
              <td>{$page.props.payment?.short_reference_id}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{$page.props.transaction?.status}
                {#if $page.props.transaction?.status_description}<br /><span>{$page.props.transaction?.status_description}</span>{/if}
              </td>
            </tr>
          </table>
        </div>

        <h2>Account details</h2>
        <div class="table-wrap">
          <table>
            <tr>
              <th>Account name</th>
              <td>{$page.props.account?.name}</td>
            </tr>
            <tr>
              <th>Bank name</th>
              <td>{$page.props.account?.bank_name}</td>
            </tr>
            <tr>
              <th>Currency</th>
              <td>{$page.props.account?.account_currency}</td>
            </tr>
            <tr>
              <th>Name on account</th>
              <td>{$page.props.account?.account_name}</td>
            </tr>
          </table>
        </div>
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  .content {
    @apply py-8 lg:py-6 px-6 lg:px-8;

    & > div {
      @apply w-full pt-4 max-w-6xl;
    }
  }

  .header {
    @apply flex justify-between items-end;

    h1 {
      @apply text-3xl md:text-4xl mb-3;
    }
  }

  h2 {
    @apply text-lg mt-8;
  }

  .table-wrap {
    @apply mt-4 mb-8 border border-grey-200 sm:rounded-lg;
  }

  table {
    @apply min-w-full divide-y divide-grey-200;

    th, td {
      @apply py-3 px-4 text-left text-sm align-top text-grey-800;
    }

    th {
      @apply font-semibold;
    }

    td span {
      @apply block text-blue-400 text-xs;
    }
  }
</style>
