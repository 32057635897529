<script>
  import {IconWallet} from "@/Components/Assets/Icons";
  import Icon from "@/Components/Assets/Icon.svelte";

  export let total = 0;
</script>

<div class="wallet-wrap">
  <div class="wallet">
    <a class="icon" href="/app/wallet">
      <Icon data={IconWallet} size="xl" />
    </a>

    <div>
      <div class="title">Wallet Amount</div>
      <div class="total">${total ?? 0}</div>
    </div>

    <a href="/app/wallet" class="withdraw">Withdraw</a>
  </div>
</div>

<style lang="postcss">
  .wallet-wrap {
    @apply lg:fixed lg:bottom-0 lg:left-0 bg-white lg:bg-transparent;
  }

  .wallet {
    @apply rounded-lg border border-blue-50 p-3 flex w-full items-start my-4 lg:ml-4 lg:w-72 bg-white;

    .title {
      @apply text-xs text-grey-700 mb-1.5 leading-none;
    }

    .total {
      @apply font-bold text-xl leading-none text-blue;
    }
  }

  a {
    @apply bg-blue-50 p-2 rounded-lg mr-3;
  }

  .withdraw {
    @apply text-red-500 underline text-xs font-medium ml-auto inline-block bg-transparent appearance-none p-0 rounded-none;
  }
</style>
