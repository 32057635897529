<script>
  import Layout from '@/Layouts/User.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {inertia, page} from "@inertiajs/svelte";
  import Checkout from "@/Components/Shop/PitchCheckout.svelte";
  import {IconTick} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
</script>

<Seo title={`Pitch ${$page.props?.profile?.name}`} />

<Layout profile={$page.props?.profile}>
  <div class="wrap">
    <div class="head">
      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Write your pitch</p>
      </div>

      <span class="ellipsis"></span>

      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Make an offer</p>
      </div>

      <span class="ellipsis"></span>
      <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
    </div>

    <Checkout
      stripe_key={$page.props.stripe_key}
      payment_methods={$page.props?.payment_methods}
      client_secret={$page.props?.client_secret}
    />

    <div class="step-indicator">
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <div class="dot"></div>
      <div class="dot active"></div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
