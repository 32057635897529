<script>
export let heading = '';
export let checkboxes = false;
</script>

<div {...$$restProps}>
  <p class="text-xs mt-0 mb-1.5 font-normal">{heading}</p>
  <div class="flex items-start flex-wrap pb-1">
    {#if checkboxes}
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1.5 w-full">
        <slot />
      </div>
    {:else}
      <slot />
    {/if}
  </div>
</div>
