<script>
  // @ts-check
  import classNames from "classnames";

  /**
   * @typedef {'color' | 'dark' | 'light'} ThemeColor
   * @typedef {'icon' | 'text' | 'full'} LogoType
   */

  /** @satisfies {ThemeColor} */
  export let theme = 'color';

  /** @satisfies {LogoType} */
  export let type = 'full';
  export let href = '';
  export let outerClass = '';
</script>

<svelte:element class={classNames('logo', outerClass)} {href} this={href ? 'a' : 'div'}>
  {#if type === 'full'}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1194.8 302.6" role="img" {...$$restProps}
         class={classNames(theme, type, $$props.class)}>
      <title>Pitchfire</title>
      <path class="fill-red-800"
            d="M176.7 129.1c-4.9-18.9-11.9-35.2-20-49.1-2.4 32.9-24.1 44.9-24.1 44.9-2.6-21.9-10.8-41.4-17.4-54.2-10.5 15.9-19.6 35.2-25.7 58.4-2.5 9.4-14.7 55.9 17.2 89.2 7 7.3 16.3 12.2 26.4 15.4 10.1-3.2 19.4-8 26.4-15.4 31.9-33.4 19.7-79.8 17.2-89.2z" />
      <path class="fill-red"
            d="M242.7 62.9s-27.4 15.8-29.5 57c-1.1-10.5-5.3-26.7-19.1-43.8-23.6-29.1-11.8-62.9 13.3-76 0 0-9.3 2.4-22.9 9.2-13.9 6.9-32.3 18.5-49.6 36.8-6.8 7.2-13.4 15.3-19.6 24.7 6.6 12.8 14.8 32.3 17.4 54.2 0 0 21.7-12 24.1-44.9 8.1 14 15 30.2 20 49.1 2.5 9.4 14.7 55.9-17.2 89.2-7 7.3-16.3 12.2-26.4 15.4 35.9 11.2 83 .6 83 .6s48.6-13.6 50.1-68.5c1.4-55.1-37.4-44.3-23.6-103z" />
      <path class="fill-blue"
            d="M106.7 218.3c-31.9-33.3-19.6-79.8-17.2-89.2 6.1-23.3 15.2-42.5 25.7-58.4-5-9.7-9.1-15.6-9.1-15.6-11.8-17-19.5-25.2-24.3-29.3 5.7 15.1 4 33.4-9.6 50.3-13.8 17.1-18 33.3-19.1 43.8-2.1-41.1-29.5-57-29.5-57 13.7 58.7-25 47.9-23.6 102.8 1.5 54.9 50.1 68.5 50.1 68.5s47.1 10.6 83-.6c-10.1-3.1-19.3-8-26.4-15.3zm371.4-95.4v115.8h-36.9V122.9h36.9zm61.2 31.9v83.9h-36.9V80.4h36.9v42.5h24.8v31.9h-24.8zm128.1 5.1c-7-7.4-15.1-10.6-24.6-10.6-15.7 0-29.5 13.4-29.5 31.6 0 18.2 13.7 31.6 29.5 31.6 10.7 0 20.5-5.1 27.1-14.4l23.6 18.1c-12.5 15.4-31.2 25.3-51.9 25.3-35.3 0-64-27.1-64-60.6s28.6-60.6 64-60.6c18.5 0 35.6 7.9 48.3 21l-22.5 18.6z" />
      <path class="fill-red"
            d="M997.8 122.9h31.4v15.7c11-12.7 21.8-18.4 32.7-18.4 6.7 0 12.9 1.7 18.4 5.6l-12.8 23.4c-4.4-2.3-8.9-3.3-12.9-3.3-14.3 0-25.5 12.5-25.5 28.6v64.2h-31.4V122.9zm112.2 63.4c.3 18.4 11.8 31.8 30.2 31.8 13.2 0 21.6-4.7 32.3-14.6l17.8 14.9c-13.5 14-28.4 23.1-50.8 23.1-34.8 0-60.6-24.6-60.6-60.6s25.7-60.6 58-60.6c33.7 0 57.8 24.9 57.9 66.1H1110zm53.6-19.5c-3.6-14.6-13.5-23.3-26.7-23.3-12.6 0-22.1 8.7-25.7 23.3h52.4zm-222.4-43.9h31.3v115.8h-31.3zM441.1 50.6h36.5v36.5h-36.5zM917 26c-33.5 0-58.8 23.4-58.8 64.3v212.3h31.2V154.9h25.4v-32.1h-25.4V91.5c0-24.7 10.8-38.4 27.6-38.4 9.6 0 15.8 1.7 23.4 6.1l23.1-19C950.3 30.8 935.8 26 917 26z" />
      <path class="fill-blue"
            d="M357.7 50.2h-62.5v188.5H334v-70h23.6c34.8 0 63.5-21.6 63.5-59.2s-28.7-59.3-63.4-59.3zm-4.5 86.3H334V84.1h19.1c16.2 0 29.3 8.2 29.3 25.3 0 18.3-13 27.1-29.2 27.1zM784 120.3c-12.8 0-25.4 4.5-33.8 13V25.8h-37.1V239h37.1v-63.6c.8-16.7 8.9-26.1 24-26.1 14 0 21.5 9.8 21.5 29.5v60h37V176c-.1-37.1-16.6-55.7-48.7-55.7z" />
    </svg>
  {:else if type === 'text'}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 899.7 276.9" role="img" {...$$restProps}
         class={classNames(theme, $$props.class)}>
      <title>Pitchfire</title>
      <path class="fill-blue"
            d="M182.9 97.2V213H146V97.2h36.9zm61.2 31.9V213h-36.9V54.7h36.9v42.5H269v31.9h-24.9zm128.2 5c-7-7.4-15.1-10.6-24.6-10.6-15.7 0-29.5 13.4-29.5 31.6 0 18.2 13.7 31.6 29.5 31.6 10.7 0 20.5-5.1 27.1-14.4l23.6 18.1c-12.5 15.4-31.2 25.3-51.9 25.3-35.3 0-64-27.1-64-60.6s28.6-60.6 64-60.6c18.5 0 35.6 7.9 48.3 21l-22.5 18.6z" />
      <path class="fill-red"
            d="M702.6 97.2H734v15.7c11-12.7 21.8-18.4 32.7-18.4 6.7 0 12.9 1.7 18.4 5.6l-12.8 23.4c-4.4-2.3-8.9-3.3-12.9-3.3-14.3 0-25.5 12.5-25.5 28.6V213h-31.4V97.2zm112.3 63.3c.3 18.4 11.8 31.8 30.2 31.8 13.2 0 21.6-4.7 32.3-14.6l17.8 14.9c-13.5 14-28.4 23.1-50.8 23.1-34.8 0-60.6-24.6-60.6-60.6s25.7-60.6 58-60.6c33.7 0 57.8 24.9 57.9 66.1h-84.8zm53.5-19.5c-3.6-14.6-13.5-23.3-26.7-23.3-12.6 0-22.1 8.7-25.7 23.3h52.4zM646.1 97.2h31.3V213h-31.3zM146 24.9h36.5v36.5H146zM621.8.2C588.3.2 563 23.6 563 64.5v212.3h31.2V129.1h25.4v-32h-25.4V65.7c0-24.7 10.8-38.4 27.6-38.4 9.6 0 15.8 1.7 23.4 6.1l23.1-19C655.2 5 640.6.2 621.8.2z" />
      <path class="fill-blue"
            d="M62.5 24.5H0V213h38.9v-70h23.6c34.8 0 63.5-21.6 63.5-59.2S97.3 24.5 62.5 24.5zm-4.4 86.2H38.9V58.4H58c16.2 0 29.3 8.2 29.3 25.3 0 18.2-13.1 27-29.2 27zm430.7-16.2c-12.8 0-25.4 4.5-33.8 13V0h-37v213.2h37.1v-63.6c.8-16.7 8.9-26.1 24-26.1 14 0 21.5 9.8 21.5 29.5v60h37v-62.8c-.1-37.1-16.7-55.7-48.8-55.7z" />
    </svg>
  {:else}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.3 238.8">
      <title>Pitchfire</title>
      <path class="fill-red-800"
            d="M176.7 129.1c-4.9-18.9-11.9-35.2-20-49.1-2.4 32.9-24.1 44.9-24.1 44.9-2.6-21.9-10.8-41.4-17.4-54.2-10.5 15.9-19.6 35.2-25.7 58.4-2.5 9.4-14.7 55.9 17.2 89.2 7 7.3 16.3 12.2 26.4 15.4 10.1-3.2 19.4-8 26.4-15.4 31.9-33.4 19.7-79.8 17.2-89.2z" />
      <path class="fill-red"
            d="M242.7 62.9s-27.4 15.8-29.5 57c-1.1-10.5-5.3-26.7-19.1-43.8-23.6-29.1-11.8-62.9 13.3-76 0 0-9.3 2.4-22.9 9.2-13.9 6.9-32.3 18.5-49.6 36.8-6.8 7.2-13.4 15.3-19.6 24.7 6.6 12.8 14.8 32.3 17.4 54.2 0 0 21.7-12 24.1-44.9 8.1 14 15 30.2 20 49.1 2.5 9.4 14.7 55.9-17.2 89.2-7 7.3-16.3 12.2-26.4 15.4 35.9 11.2 83 .6 83 .6s48.6-13.6 50.1-68.5c1.4-55.1-37.4-44.3-23.6-103z" />
      <path class="fill-blue"
            d="M106.7 218.3c-31.9-33.3-19.6-79.8-17.2-89.2 6.1-23.3 15.2-42.5 25.7-58.4-5-9.7-9.1-15.6-9.1-15.6-11.8-17-19.5-25.2-24.3-29.3 5.7 15.1 4 33.4-9.6 50.3-13.8 17.1-18 33.3-19.1 43.8-2.1-41.1-29.5-57-29.5-57 13.7 58.7-25 47.9-23.6 102.8 1.5 54.9 50.1 68.5 50.1 68.5s47.1 10.6 83-.6c-10.1-3.1-19.3-8-26.4-15.3z" />
    </svg>
  {/if}
</svelte:element>

<style lang="postcss">
  a.logo {
    @apply cursor-pointer;
  }

  .logo {
    .light path {
      @apply !fill-white;
    }
    .dark path {
      @apply !fill-black;
    }
  }
</style>
