<script>
  import classNames from 'classnames';

  export let title = '';

  /** @type {import('@/Lib/types/component.d').TagSizeType} size */
  export let size = 'sm';

  /**
   * @typedef {'green' | 'red' | 'violet' | 'yellow' | 'blue' | 'grey'} PillColor
   */

  /** @satisfies PillColor */
  export let color = 'green';
</script>

<div {...$$restProps} class={classNames($$props.class, size, color)}>
  <slot>{title}</slot>
</div>

<style lang="postcss">
  div {
    @apply rounded py-1 px-1.5 bg-grey-700 text-white inline-flex items-center justify-start gap-1 text-xs whitespace-nowrap leading-none;

    &.md {
      @apply py-1.5 px-2 text-sm;
    }

    &.lg {
      @apply py-2 px-2.5 text-base;
    }

    &.green {
      @apply bg-green-700;
    }

    &.blue {
      @apply bg-blue-700;
    }

    &.violet {
      @apply bg-red-700;
    }

    &.red {
      @apply bg-red-700;
    }

    &.yellow {
      @apply bg-yellow-700;
    }

    &.grey {
      @apply bg-grey-200 text-black;
    }
  }
</style>
