<script>
  import Layout from "@/Layouts/Respond.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Pill from "@/Components/Elements/Pill.svelte";

  const setResponseStatus = (status) => {
    if (status === 'Accepted') {
      return 'Interested';
    }
    else if (status === 'Rejected') {
      return 'Not Interested';
    }
  }
</script>

<Seo title={`$${$page.props?.response?.fee} Pitch from ${$page.props?.response?.user?.name}`} />

<Layout>
  <div class="pitch">
    <h4>Review Pitch</h4>

    {#if $page.props?.response?.pitch?.id}
      <div class="body plain">{$page.props?.response?.pitch_body}</div>
    {/if}

    {#if $page.props?.response?.campaign_link?.id}
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="body">{@html $page.props?.response?.pitch_body}</div>
    {/if}
  </div>

  <div class="response">
    <div class="title">
      <span>Your response to {$page.props?.response?.seller?.name}</span>

      <Pill
        color={$page.props?.response?.status === 'Accepted' ? 'green' : 'red'}
        title={setResponseStatus($page.props?.response?.status)}
      />
    </div>

    {#if $page.props?.response?.reason}
      <div class="reason">
        {#if $page.props?.response?.status === 'Rejected'}
          <span>{$page.props?.response?.reason}</span>
        {:else}
          <span>I am interested in meeting with you and {$page.props?.response?.seller?.org_name} because...</span>
        {/if}
      </div>
    {/if}

    {#if $page.props?.response?.body}
      <div class="body">{$page.props?.response?.body}</div>
    {/if}

    {#if $page.props?.meeting?.meeting_datetime}
      <div class="title">Meeting Details</div>
      <div class="meeting">
        <div>
          <div class="heading">Date & Time</div>
          <div class="date">{$page.props?.meeting?.meeting_datetime}</div>
          <div class="heading">Time Zone</div>
          <div class="timezone">{$page.props?.meeting?.meeting_timezone}</div>

          <div class="links">
            {#if $page.props?.response?.pitch?.meeting_link}
              <a href={$page.props?.response?.pitch?.meeting_link} target="_blank" rel="noopener noreferrer">Meeting Link</a>
            {/if}

            {#if $page.props?.meeting?.event_link}
              <a href={$page.props?.meeting?.event_link} target="_blank" rel="noopener noreferrer">View in Calendar</a>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply flex flex-col relative mb-4;

    h4 {
      @apply text-base mb-4;
    }

    .body {
      @apply border p-4 rounded-xl;

      &.plain {
        @apply whitespace-pre-line;
      }
    }
  }

  .response {
    @apply mt-8;

    .title {
      @apply text-base font-semibold mb-2;
    }

    .reason {
      @apply text-sm font-semibold mb-2;
    }

    .body {
      @apply text-sm mb-6 whitespace-pre-line;
    }

    .meeting {
      @apply p-4 bg-grey-100 rounded-xl mt-2 flex justify-between items-end;

      .heading {
        @apply text-sm font-semibold mb-2;
      }

      .date,
      .timezone {
        @apply text-lg leading-none;
      }

      .date {
        @apply mb-4;
      }

      .links {
        @apply mt-4 flex gap-6;

        a {
          @apply font-semibold;
        }
      }
    }
  }
</style>
