<script>
  import classNames from "classnames";

  let accordions = [
    { question: "What the hell is Pitchfire?", answer: "<p>Every year, 647 billion prospecting touches are sent by businesses to build pipeline. Only 0.7% will get a response. Imagine if every buyer you contacted responded to you. How much easier would selling be?</p><p>Pitchfire incentivizes buyers to stop ignoring your outreach and respond. </p><p>Submit your pitch and save time, money, and effort by paying your prospects to respond here.</p>", expanded: false },
    { question: "How does this thing  work?", answer: "<p>A buyer can send you right to their Pitchfire listing via sharing their Pitchfire link or using our inbox plugin. You can also find them in our marketplace.</p><p>Write a short pitch, submit an offer, put in a credit card, and we'll notify them to come in and respond.</p><p>If they don't respond, we don't charge your business.</p>", expanded: false },
    { question: "What kind of responses will I get?", answer: "<p>Responses on Pitchfire are generally better than replies over email or LinkedIn.</p><p>They can give you insight into how to sell to their company, tell you if they are the right person or not, say who is the right person... if they are happy with a competitor… their priorities... why they are interested or not.</p><p>If they like your pitch, they'll book on your calendar through Pitchfire in one click.</p>", expanded: false },
    { question: "Who is getting this money?", answer: "<p>The person you’re pitching gets paid the money, minus transactional fees. They don't get paid unless they respond to your sales pitch. Currently, pitches expire every 7 business days.</p>.", expanded: false },
    { question: "Is this legal? Isn't this a bribe?", answer: `<p>Look, we spent a ton of money to find out how to make this legal haha.</p><p>Buyers get paid by your business the same amount of money regardless of how they respond. Because of this, there is no conflict of interests or "bribe" happening.</p><p>They don't make additional income for buying your product or taking a meeting. They get paid simply to respond to you. This avoids any conflicts or corruption.</p><p>Members of Pitchfire have adopted this as their policy for when they receive outbound prospecting.</p>`, expanded: false },
    { question: "Prospecting is free. Why do I have to pay to get a response?", answer: `<p>The concept of paid prospecting is new. Think of this like a highway to building pipeline.</p><p>Most B2B products or services cost north of $10k-$100k annually. Companies pay an average of $3,600 to get a prospect to talk with them.</p><p>The average person who received a cold call or email makes less than $80k annually and has over $90k in debt. Pitchfire is skipping the whole dog and pony show and giving customer acquisition cost directly back to buyers.</p>`, expanded: false },
  ];

  /** @type {string} title */
  export let title = '';

  function toggleAccordion(index) {
    accordions = accordions.map((accordion, i) => ({
      ...accordion,
      expanded: i === index ? !accordion.expanded : false
    }));
  }

  function handleAccordionKeyDown(event, index) {
    if (event.key === "Enter" || event.key === " ") {
      toggleAccordion(index);
    }
  }
</script>

<div {...$$restProps} class={$$props.class}>
  {#if title}
    <h3>{title}</h3>
  {/if}

  <div class="accordion">
    {#each accordions as accordion, index}
      <div class="accordion--item" class:expanded={accordion.expanded}>
        <button
          class={classNames('accordion--head', accordion.expanded ? 'open' : '')}
          on:click={() => toggleAccordion(index)}
          on:keydown={(event) => handleAccordionKeyDown(event, index)}
        >
          <span>{accordion.question}</span>
        </button>

        {#if accordion.expanded}
          <div class="accordion--body">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            <p>{@html accordion.answer}</p>
          </div>
        {/if}
      </div>
    {/each}
  </div>
</div>

<style lang="postcss">
  h3 {
    @apply text-base font-semibold;
  }

  .accordion {
    @apply rounded-lg overflow-hidden;

    &--item {
      @apply bg-blue-100 border-b border-grey-500;

      &:last-child {
        @apply border-b-0;
      }
    }

    &--head {
      @apply text-left font-semibold cursor-pointer md:px-10 px-5 py-5 flex justify-between items-center gap-4 w-full;

      &.open {
        &::after {
          @apply bg-[url('/assets/minus.svg')];
        }
      }

      &::after {
        content: "";
        @apply h-5 w-5 bg-[url('/assets/plus.svg')] inline-block bg-no-repeat bg-center;
      }
    }

    &--body {
      @apply text-base text-grey-800 md:px-10 px-5 pb-5;
    }
  }
</style>
