<script>
  import Layout from "@/Layouts/Upload.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  const form = useForm({
    first_name: $page.props?.import?.mappings?.first_name,
    last_name: $page.props?.import?.mappings?.last_name,
    name: $page.props?.import?.mappings?.name,
    email: $page.props?.import?.mappings?.email,
    job_title: $page.props?.import?.mappings?.job_title,
    state: $page.props?.import?.mappings?.state,
    country: $page.props?.import?.mappings?.country,
    linkedin_url: $page.props?.import?.mappings?.linkedin_url,
    org_name: $page.props?.import?.mappings?.org_name,
    domain: $page.props?.import?.mappings?.domain,
    org_linkedin: $page.props?.import?.mappings?.org_linkedin,
  }),
  handleSubmit = (event) => {
    event.preventDefault();
    $form.post(`/app/events/${$page.props?.import?.id}/upload/map/save`);
  }
</script>

<Layout
  title={`${$page.props?.conference?.name  } - Mappings`}
  conference={$page.props?.conference}
  conferenceImport={$page.props?.import}
>
  <h2 class="block text-lg mt-8 mb-4">Map Headings</h2>

  <form on:submit={(e) => handleSubmit(e)}>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <Select
        label="First Name"
        name="first_name"
        size="sm"
        bind:value={$form.first_name}
        error={$form.errors.first_name}
        options={$page.props?.options}
      />

      <Select
        label="Last Name"
        name="last_name"
        size="sm"
        bind:value={$form.last_name}
        error={$form.errors.last_name}
        options={$page.props?.options}
      />

      <Select
        label="Full Name"
        name="name"
        size="sm"
        required
        bind:value={$form.name}
        error={$form.errors.name}
        options={$page.props?.options}
      />

      <Select
        label="Email"
        name="email"
        size="sm"
        required
        bind:value={$form.email}
        error={$form.errors.email}
        options={$page.props?.options}
      />

      <Select
        label="Job Title"
        name="job_title"
        size="sm"
        bind:value={$form.job_title}
        error={$form.errors.job_title}
        options={$page.props?.options}
      />

      <Select
        label="State"
        name="state"
        size="sm"
        bind:value={$form.state}
        error={$form.errors.state}
        options={$page.props?.options}
      />

      <Select
        label="Country"
        name="country"
        size="sm"
        bind:value={$form.country}
        error={$form.errors.country}
        options={$page.props?.options}
      />

      <Select
        label="LinkedIn URL"
        name="linkedin_url"
        size="sm"
        bind:value={$form.linkedin_url}
        error={$form.errors.linkedin_url}
        options={$page.props?.options}
      />

      <Select
        label="Company Name"
        name="org_name"
        size="sm"
        bind:value={$form.org_name}
        error={$form.errors.org_name}
        options={$page.props?.options}
      />

      <Select
        label="Company Domain"
        name="domain"
        size="sm"
        required
        bind:value={$form.domain}
        error={$form.errors.domain}
        options={$page.props?.options}
      />

      <Select
        label="Company LinkedIn"
        name="org_linkedin"
        size="sm"
        bind:value={$form.org_linkedin}
        error={$form.errors.org_linkedin}
        options={$page.props?.options}
      />
    </div>

    <div class="mt-4">
      <Button type="submit" on:click={(e) => handleSubmit(e)} size="sm" color="secondary">Save</Button>
    </div>
  </form>
</Layout>
