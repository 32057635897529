<script>
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconComment, IconFlame, IconSend} from "@/Components/Assets/Icons/index.js";

  export let cards = [
    {
      image: IconFlame,
      title: "Pick your buyer",
      description:
        "<p>There are thousands of buyers at your finger tips. We see you drooling.</p>" +
        "<p>Who do you want to pitch?</p>" +
        "<p>New people are joining Pitchfire everyday, opting into responding to sales pitches for money.</p>",
    },
    {
      image: IconSend,
      title: "Write your pitch",
      description: "<p>Tired of secretly crying in the shower because prospects won't respond to you?</p>" +
        "<p>Skip the flood of cold calls, emails, DMs, and tears.</p>" +
        "<p>Take your best shot pitching a meeting.</p>",
    },
    {
      image: IconComment,
      title: "Buy your answer",
      description:
        "<p>We only charge you if you get a response.</p>" +
        "<p>The more you offer, the higher you come up in your prospect's Pitchfire inbox.</p>" +
        "<p>Worst case scenario, you get some intel. Best case scenario, you get the meeting.</p>",
    },
  ];

  /** @type {string} title */
  export let title = "";
</script>

<div {...$$restProps}>
  <h2>{title}</h2>

  <div class="card-row">
    {#each cards as card}
      <div class="card">
        <span class="icon">
          <Icon data={card.image} class="fill-white !w-full !h-auto" />
        </span>

        <h3>{card.title}</h3>

        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <div class="desc">{@html card.description}</div>
      </div>
    {/each}
  </div>
</div>

<style lang="postcss">
  h2 {
    @apply text-xl sm:text-3xl md:text-4xl text-blue text-center font-semibold mb-10;
  }

  .card-row {
    @apply grid md:grid-cols-3 gap-8 md:gap-4 lg:gap-7;
  }

  .card {
    @apply bg-blue-800 px-4 md:px-6 pt-8 lg:pt-12 pb-4 lg:pb-6 rounded-lg text-center;

    .icon {
      @apply h-11 w-11 inline-flex justify-between items-center mb-8;
    }

    h3 {
      @apply text-base font-semibold text-white mb-2;
    }

    .desc {
      @apply text-sm text-white mb-0;
    }
  }
</style>
