<script>
  import Layout from "@/Layouts/Auth.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {IconChrome, IconGoogle, IconMicrosoft} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {useForm} from '@inertiajs/svelte';

  let checkInstallation = false,
    form = useForm({});

  const handleClick = (event) => {
    event.target.click();
    checkInstallation = true;
  },
  handleBack = (event) => {
    event.preventDefault();
    checkInstallation = false;
  },
  verifyInstallation = (event) => {
    event.preventDefault();
    $form.post('/app/onboarding/plugin/verify');
  }
</script>

<Seo title="Install the Inbox Plugin" />

<Layout title="Install the Inbox Plugin" page={3}>
  <div class="body">
    {#if ! checkInstallation}
      <p>Send every cold email to Pitchfire with our inbox plugins.</p>

      <div class="flex flex-col gap-3 items-start">
        <Button
          dynamic={false}
          class="!text-sm !gap-3"
          color="red"
          href="https://chromewebstore.google.com/detail/pitchfire/ipblfhcfgceibpobegdodamekfkeohdf"
          rel="noreferrer noopener"
          target="_blank"
          on:click={handleClick}
        >
          <Icon size="lg" data={IconChrome} class="fill-white" />
          <span>Chrome Extension</span>
        </Button>

        <Button
          dynamic={false}
          class="!text-sm !gap-3"
          color="red"
          href="https://workspace.google.com/marketplace/app/request_for_meeting/126639686392"
          rel="noreferrer noopener"
          target="_blank"
          on:click={handleClick}
        >
          <Icon size="lg" data={IconGoogle} class="fill-white" />
          <span>Google Workspace</span>
        </Button>

        <Button
          dynamic={false}
          class="!text-sm"
          color="red"
          href="https://appsource.microsoft.com/en-GB/product/office/WA200006327"
          rel="noreferrer noopener"
          target="_blank"
          on:click={handleClick}
        >
          <Icon size="lg" data={IconMicrosoft} class="fill-white" />
          <span>Microsoft Outlook</span>
        </Button>
      </div>

    {:else}
      <p class="mt-8">Once you've installed the plugin on your inbox, please click the button below to verify your installation.</p>
      <Button on:click={(e) => verifyInstallation(e)} size="sm" loading={$form.processing}>Verify Installation</Button>

      <div class="flex gap-3 justify-start mt-4">
        <a href="/app/onboarding/plugin" on:click={(e) => handleBack(e)} size="sm">Back</a>
      </div>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .body {
    @apply text-sm;
  }
</style>
