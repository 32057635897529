<script>
  import Layout from "@/Layouts/App.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import YouTube from "@/Components/Elements/YouTube.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
</script>

<Seo title="Dashboard" />

<Layout>
  <div class="content">
    <h1>Home</h1>
    <Breadcrumb crumbs={[
      { title: 'Menu', url: '/' },
      { title: 'Home' }
    ]} />

    <div class="body">
      <YouTube id="J2c9UAKPBpU" />
    </div>
  </div>
</Layout>

<style lang="postcss">
  .content {
    @apply py-8 lg:py-6 px-6 lg:px-8;

    h1 {
      @apply text-3xl md:text-4xl mb-3;
    }

    .body {
      @apply w-full py-8 lg:py-10 text-center flex items-center justify-center flex-col max-w-6xl;
    }
  }
</style>
