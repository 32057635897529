<script>
  import Layout from '@/Layouts/Auth.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, useForm} from '@inertiajs/svelte'
  import Label from "@/Components/Forms/Label.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import cn from "classnames";

  const form = useForm({
    first_name: '',
    last_name: '',
    email: $page.props?.email
  }),

   submit = () => {
    $form.post('/auth/register');
  };
</script>

<Seo title="Register" />

<Layout>
  {#if $page.props.auth.loggedIn}
    <Profile
      image={$page.props.auth?.user?.avatar}
      imageAlt={$page.props.auth?.user?.name}
      subImage={$page.props.auth?.org?.logo}
      subImageAlt={$page.props.auth?.org?.name}
      title={$page.props.auth?.user?.name}
      subTitle={$page.props.auth?.user?.email}
      href="/app/respond"
    />

    <div class="buttons">
      <Button color="tertiary" class="!text-sm" href="/app/respond">Continue</Button>
      <Button color="tertiary" class="!text-sm" href="/logout">Log out</Button>
    </div>
  {:else}
    <form on:submit|preventDefault={() => submit()}>
      <div>
        <Label size="sm" name="first_name" label="First Name" />

        <Input
          id="first_name"
          type="text"
          name="first_name"
          inputClass={cn($form.errors.first_name ? 'error' : '', '!bg-white !text-sm !rounded min-h-10')}
          bind:value={$form.first_name}
          autocomplete="given-name"
          placeholder="Enter your first name"
          bind:error={$form.errors.first_name}
          size="sm"
          autofocus
          required
        />
      </div>

      <div class="mt-4">
        <Label size="sm" name="last_name" label="Last Name" />

        <Input
          id="last_name"
          type="text"
          name="last_name"
          inputClass={cn($form.errors.last_name ? 'error' : '', '!bg-white !text-sm !rounded min-h-10')}
          bind:value={$form.last_name}
          class="mt-1 block w-full"
          autocomplete="family-name"
          size="sm"
          placeholder="Enter your last name"
          bind:error={$form.errors.last_name}
          required
        />
      </div>

      <div class="mt-4">
        <Label size="sm" name="email" label="Email" />
        <Input
          id="email"
          type="email"
          inputClass={cn($form.errors.email ? 'error' : '', '!bg-white !text-sm !rounded min-h-10')}
          disabled
          size="sm"
          name="email"
          bind:value={$form.email}
          bind:error={$form.errors.email}
          required
        />
      </div>

      <div class="flex items-center justify-between mt-4">
        <Button type="submit" loading={form.processing}>Register</Button>
      </div>
    </form>
  {/if}
</Layout>

<style lang="postcss">
  form {
    @apply md:max-w-md;
  }

  .buttons {
    @apply mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2;
  }
</style>
