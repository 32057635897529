<script>
  import Layout from "@/Layouts/Public.svelte";
  import imgPin from "/assets/extension-pin.gif";
  import imgGmail from "/assets/extension-gmail.png";
  import imgLinkedIn from "/assets/extension-linkedin.png";
  import {page} from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
</script>

<Layout class="max-w-7xl text-center p-4">
  <Seo title="Get started with the Pitchfire extension" />

  <h1 class="text-2xl md:text-3xl mb-6">Your new policy for cold outreach</h1>
  <p>Start sending sales people to Pitchfire.</p>

  <div class="flex flex-col md:flex-row gap-8 mt-12">
    <div class="flex flex-col gap-4 md:w-1/3">
      <img src={imgPin} alt="Pin the Pitchfire extension" class="overflow-hidden rounded-lg mx-auto max-w-full w-[440px] md:max-w-[220px] lg:max-w-[320px] 2xl:max-w-[400px]">

      <div>
        <div class="rounded-full flex items-center justify-center w-7 h-7 bg-red font-bold text-white my-4 leading-none m-auto">1</div>
        <h2 class="text-base md:text-xl mb-2">Pin the extension & click to run</h2>
        <p class="text-sm">Find and pin the Pitchfire Chrome Extension from the extension puzzle icon at the top of your browser.</p>
      </div>
    </div>

    <div class="flex flex-col gap-4 md:w-1/3">
      <img src={imgGmail} alt="Send sales emails to Pitchfire from Gmail" class="overflow-hidden rounded-lg mx-auto max-w-full w-[440px] md:max-w-[220px] lg:max-w-[320px] 2xl:max-w-[400px]">

      <div>
        <div class="rounded-full flex items-center justify-center w-7 h-7 bg-red font-bold text-white my-4 leading-none m-auto">2</div>
        <h2 class="text-base md:text-xl mb-2">Send sales emails</h2>
        <p class="text-sm">Login to <a href="https://mail.google.com" target="_blank" rel="noopener noreferrer">Gmail</a>, and send sales emails to Pitchfire from the list or message views. We also identify potential sales emails for you.</p>
      </div>
    </div>

    <div class="flex flex-col gap-4 md:w-1/3">
      <img src={imgLinkedIn} alt="Send LinkedIn sales messages to Pitchfire" class="overflow-hidden rounded-lg mx-auto max-w-full w-[440px] md:max-w-[220px] lg:max-w-[320px] 2xl:max-w-[400px]">

      <div>
        <div class="rounded-full flex items-center justify-center w-7 h-7 bg-red font-bold text-white my-4 leading-none m-auto">3</div>
        <h2 class="text-base md:text-xl mb-2">Send LinkedIn messages</h2>
        <p class="text-sm">Reply to sales messages on LinkedIn with your <a href={$page.props?.auth?.user?.url}>Pitchfire link</a>. View Pitchfire users on LinkedIn.</p>
      </div>
    </div>
  </div>
</Layout>
