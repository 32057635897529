<script>
  import {createEventDispatcher, onDestroy} from "svelte";
  import Loader from "@/Components/Elements/Loader.svelte";

  export let threshold = 0;
  export let horizontal = false;
  export let elementScroll = null;
  export let hasMore = true;

  const dispatch = createEventDispatcher();
  let component,
   isLoadMore = false;

  $: if (component || elementScroll) {
    const element = elementScroll ? elementScroll : component.parentNode;

    element.addEventListener("scroll", onScroll);
    element.addEventListener("resize", onScroll);
  }

  const onScroll = e => {
    const element = e.target,
     offset = horizontal
      ? element.scrollWidth - element.clientWidth - element.scrollLeft
      : element.scrollHeight - element.clientHeight - element.scrollTop;

    if (offset <= threshold) {
      if (!isLoadMore && hasMore) {
        dispatch("loadMore");
      }
      isLoadMore = true;
    } else {
      isLoadMore = false;
    }
  };

  onDestroy(() => {
    if (component || elementScroll) {
      const element = elementScroll ? elementScroll : component.parentNode;

      element.removeEventListener("scroll", null);
      element.removeEventListener("resize", null);
    }
  });
</script>

{#if isLoadMore && hasMore}
  <Loader />
{/if}

<span bind:this={component}></span>
