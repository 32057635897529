<script>
  import classNames from "classnames";
  import Icon from "../Assets/Icon.svelte";
  import WarningTriangle from "../Assets/Icons/warning-triangle.svg?raw"
  import CheckCircle from "../Assets/Icons/check-circle.svg?raw"
  import InfoCircle from "../Assets/Icons/info-circle.svg?raw"
  import Stop from "../Assets/Icons/stop.svg?raw"
  import {IconClose} from "../Assets/Icons";

  /**
   * @typedef {'error' | 'warning' | 'success' | 'info'} AlertType
   */

  /** @satisfies AlertType */
  export let type;

  export let id = '';
  export let title = '';
  export let message = '';
  export let messageHtml = '';
</script>

<div class={classNames($$props.class, 'alert', type)}>
  <div class="flex">
    <div class="flex-shrink-0">
      {#if type === 'warning'}
        <Icon data={WarningTriangle} size="lg" class="fill-yellow-800" />
      {:else if type === 'error'}
        <Icon data={Stop} size="lg" class="fill-red-800" />
      {:else if type === 'success'}
        <Icon data={CheckCircle} size="lg" class="fill-green-800" />
      {:else if type === 'info'}
        <Icon data={InfoCircle} size="lg" class="fill-violet" />
      {/if}
    </div>
    <div class="ml-2">
      {#if title}<h5>{title}</h5>{/if}
      {#if message || messageHtml || $$slots}
        <div class="msg">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          <slot>{message}{@html messageHtml}</slot>
        </div>
      {/if}
    </div>

    {#if id}
      <div class="action">
        <button on:click={() => console.log(id)} type="button">
          <span class="sr-only">Dismiss</span>
          <Icon data={IconClose} />
        </button>
      </div>
    {/if}
  </div>
</div>

<style lang="postcss">
  .alert {
    @apply rounded-lg px-2 py-2 shadow-sm border border-grey-200;

    &.error {
      @apply bg-red-50;

      h5 {
        @apply text-red-800;
      }

      .msg {
        @apply text-red-700;
      }

      button {
        @apply fill-red-700;
      }
    }

    &.warning {
      @apply bg-yellow-50;

      h5 {
        @apply text-yellow-800;
      }

      .msg {
        @apply text-yellow-700;
      }

      button {
        @apply fill-yellow-700;
      }
    }

    &.success {
      @apply bg-green-50;

      h5 {
        @apply text-green-800;
      }

      .msg {
        @apply text-green-700;
      }

      button {
        @apply fill-green-700;
      }
    }

    &.info {
      @apply bg-violet-50;

      h5 {
        @apply text-violet-800;
      }

      .msg {
        @apply text-violet-700;
      }

      button {
        @apply fill-violet-700;
      }
    }

    h5 {
      @apply text-sm mb-0.5 leading-4 font-semibold;
    }

    .msg {
      @apply text-sm;
    }

    .action {
      @apply ml-auto pl-3;
    }

    button {
      @apply hover:fill-black pl-2 pb-2;
    }
  }
</style>
