<script>
  import Icon from "@/Components/Assets/Icon.svelte";
  import Layout from "@/Layouts/Settings.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {
    IconGoogleColor,
    IconGoogleMeet,
    IconMicrosoftColor,
    IconMicrosoftTeams,
    IconZoom
  } from "@/Components/Assets/Icons";
  import {page, useForm} from "@inertiajs/svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Input from "@/Components/Forms/Input.svelte";

  const form = useForm({
    meeting_link: $page.props?.auth?.user?.meeting_link ?? ''
  }),

   handleSubmit = (event) => {
    event.preventDefault();
    $form.post('/app/settings/integrations/meeting-link');
  }
</script>

<Seo title="Integrations" />

<Layout title="Integrations">
  <div class="description">
    <h3>Meetings & Video Conferencing</h3>
    <p>Add your meeting link below.</p>
    <p>Your meeting link will be added to the calendar event on accepted pitches.</p>
  </div>

  <form method="post" on:submit={(event) => handleSubmit(event)}>
    <Input
      type="url"
      name="meeting_link"
      placeholder="Video conference link (Zoom, Google Meet, Microsoft Teams...)"
      size="sm"
      wrapperClass="max-w-lg"
      bind:value={$form.meeting_link}
      error={$form.errors?.meeting_link}
    >
      <div slot="right">
        <Button type="submit" size="sm" on:click={(event) => handleSubmit(event)}>Save</Button>
      </div>
    </Input>

    <div class="icons">
      <Icon data={IconZoom} size="xxl" />
      <Icon data={IconGoogleMeet} size="xxl" />
      <Icon data={IconMicrosoftTeams} size="xxl" />
    </div>
  </form>

  <div class="description">
    <h3>Calendar</h3>
    <p>Connecting your calendar to Pitchfire allows you to:</p>
    <ul>
      <li>Create meetings with sellers for successful pitches</li>
      <li>Sync your calendar so potentials buyers can schedule time with you</li>
      <li>Reschedule meetings you've created through Pitchfire</li>
    </ul>
  </div>

  <div class="items">
    <div class="item">
      {#if $page.props?.googleConnected}
        <div>
          <div>
            <Icon data={IconGoogleColor} size="xl" />
            <span>Google Calendar Connected</span>
            <span class="status"></span>
          </div>

          <Profile
            title={$page.props?.googleAccount?.name}
            imageAlt={$page.props?.googleAccount?.name}
            subTitle={$page.props?.googleAccount?.email}
            image={$page.props?.googleAccount?.avatar ?? $page.props.auth?.user?.avatar}
          >
            <a href="/auth/google/revoke" class="disconnect">Disconnect</a>
          </Profile>
        </div>
      {:else if !$page.props?.microsoftConnected}
        <a href={$page.props?.googleAuthUrl}>
          <Icon data={IconGoogleColor} size="xl" />
          <span>Connect your Google calendar</span>
          <span class="status"></span>
        </a>
      {/if}

      {#if $page.props?.microsoftConnected}
        <div>
          <div>
            <Icon data={IconMicrosoftColor} size="xl" />
            <span>Microsoft Calendar Connected</span>
            <span class="status"></span>
          </div>

          <Profile
            title={$page.props?.microsoftAccount?.name}
            imageAlt={$page.props?.microsoftAccount?.name}
            subTitle={$page.props?.microsoftAccount?.email}
            image={$page.props?.microsoftAccount?.avatar}
          >
            <a href="/auth/microsoft/revoke" class="disconnect">Disconnect</a>
          </Profile>
        </div>
      {:else if !$page.props?.googleConnected}
        <a href={$page.props?.microsoftAuthUrl}>
          <Icon data={IconMicrosoftColor} size="xl" />
          <span>Connect your Microsoft calendar</span>
          <span class="status"></span>
        </a>
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  h3 {
    @apply text-2xl font-semibold text-blue-800 mb-2;
  }

  p {
    @apply m-0 mb-2;
  }

  .icons {
    @apply flex gap-2 items-center mt-2;
  }

  .description {
    @apply mt-4;
  }

  form {
    @apply mt-2 mb-4 pb-8 border-b border-grey-200;
  }

  .items {
    @apply mt-8;
  }

  .item {
    @apply flex flex-col;

    a,
    & > div {
      @apply rounded-lg border border-blue p-4 max-w-md mb-6;
    }

    .status {
      @apply w-2.5 h-2.5 block rounded-full;
    }

    a {
      @apply cursor-pointer bg-white hover:bg-blue-50 hover:text-blue focus:bg-blue-50 focus:ring-2 focus:outline-none no-underline justify-center font-semibold inline-flex gap-5 items-center text-blue text-xl leading-none;

      .status {
        @apply bg-red-600;
      }
    }

    .disconnect {
      @apply bg-red border-0 text-white hover:bg-blue hover:text-white py-1 text-xs px-2.5 m-0;
    }

    & > div {
      & > div:first-child {
        @apply justify-center font-semibold inline-flex gap-5 items-center text-blue text-xl leading-none mb-4;
      }

      .status {
        @apply bg-green-600;
      }
    }
  }
</style>
