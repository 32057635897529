<script>
  import Layout from '@/Layouts/Page.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import TagGroup from "@/Components/Elements/TagGroup.svelte";
  import {
    IconGoogleColor,
    IconMicrosoftColor
  } from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {onMount} from "svelte";
  import Alert from "@/Components/Elements/Alert.svelte";

  let googleAuthUrl = '',
   microsoftAuthUrl = '';

  onMount(() => {
    googleAuthUrl = getAuthUrl('/auth/google/redirect')
    microsoftAuthUrl = getAuthUrl('/auth/microsoft/redirect')
  });

  const getAuthUrl = (authUrl, includeTimezone = true) => {
    const excluded_urls = [
      'revert-masquerade', 'logout',
      'auth/google/authorise', 'auth/microsoft/authorise',
      'auth/google/revoke', 'auth/microsoft/revoke',
      'auth/google/login', 'auth/microsoft/login',
      'auth/google/redirect', 'auth/microsoft/redirect',
    ],
     referrer = $page.props?.common.url.path,

     data = referrer.includes(excluded_urls) ? {} : {
      referrer: `/${  $page.props?.common.url.path}`
    },

     timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone && includeTimezone) {
      data.timezone =  timezone;
    }

    const urlParams = new URL(document.location).searchParams;
    if (urlParams.has('utm_medium')) {
      data.utm_medium = urlParams.get('utm_medium');
    }

    if (urlParams.has('utm_source')) {
      data.utm_source = urlParams.get('utm_source');
    }

    if (urlParams.has('utm_campaign')) {
      data.utm_campaign = urlParams.get('utm_campaign');
    }

    if (urlParams.has('utm_term')) {
      data.utm_term = urlParams.get('utm_term');
    }

    if (urlParams.has('utm_content')) {
      data.utm_content = urlParams.get('utm_content');
    }

    const query = new URLSearchParams(data);

    return query.toString() ? `${authUrl}?${query.toString()}` : authUrl;
  }
</script>

<Seo title="Review Pitch" />

<Layout nav footer>
  <div class="wrap">
    <div class="book">
      <div class="header">
        <Profile
          title={`Hi! I'm ${$page.props?.profile?.name}`}
          titleElement="h1"
          subTitleHtml={$page.props?.profile?.description}
          subTitleElement="h2"
          image={$page.props?.profile?.avatar}
          imageAlt={$page.props?.profile?.name}
          subImage={$page.props?.profile?.org_logo}
          subImageAlt={$page.props?.profile?.org_name}
          size="xl"
        >
          {#if $page.props.focuses?.length > 0}
            <TagGroup tags={$page.props.focuses.map(x => ({ title: x.name }))} />
          {/if}

          <div slot="actions" class="amount">
            ${$page.props?.campaign_link?.amount}
          </div>
        </Profile>
      </div>

      {#if $page.props?.auth.loggedIn}
        <div class="pitch">
          <Alert type="warning">
            <div class="inactive">This campaign is currently not active</div>
          </Alert>

          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          <div class="body">{@html $page.props?.campaign_link?.pitch}</div>

          <div class="actions-wrap">
            <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
          </div>
        </div>
      {:else}
        <div class="login">
          <h3>I'm offering you ${$page.props?.campaign_link?.amount} to answer my pitch.</h3>
          <p>🔥 Pitchfire lets you sell your attention to sales people.</p>
          <p>If you're interested, we'll book 30 minutes on my calendar.</p>
          <p>If you aren't, you'll still get paid. Just answer my pitch.</p>

          <div class="actions">
            <div>
              <Button
                href={googleAuthUrl}
                color="tertiary"
                dynamic={false}
                loading={googleAuthUrl === ''}
              >
                <Icon size="xl" data={IconGoogleColor} />
                <span>Continue with Google</span>
              </Button>

              <Button
                color="tertiary"
                dynamic={false}
                href={microsoftAuthUrl}
                loading={microsoftAuthUrl === ''}
              >
                <Icon size="xl" data={IconMicrosoftColor} />
                <span>Continue with Microsoft</span>
              </Button>
            </div>

            <p>By signing up, you agree to the <a href="https://www.pitchfire.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://www.pitchfire.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> of Pitchfire.</p>
          </div>
        </div>
      {/if}
    </div>

    <img src="/assets/calendar-bg.png" alt="" role="presentation" />
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply relative mb-20 min-h-full;

    & > img {
      @apply blur-sm sm:absolute right-0 top-32;
    }
  }

  .book {
    @apply border bg-white border-grey-200 shadow-lg rounded-2xl max-w-4xl mx-auto relative z-10;
  }

  .header {
    @apply bg-grey-100 border-b border-grey-200 px-6 py-4 md:px-10 md:py-8 rounded-t-2xl;

    .amount {
      @apply text-violet text-2xl font-semibold mb-2;
    }
  }

  .pitch {
    @apply px-6 py-4 md:px-10 md:py-8;

    .body {
      @apply border border-grey-200 rounded-lg p-4 text-grey-700 text-base my-4;
    }

    .actions-wrap {
      @apply mt-4 flex flex-col items-end;

      & > p {
        @apply m-0;

        span {
          @apply text-violet font-semibold;
        }
      }
    }
  }

  .login {
    @apply text-center bg-green-50/20 rounded-b-2xl px-6 py-4 md:px-10 md:py-8;

    h3 {
      @apply text-xl mb-2;
    }

    p {
      @apply my-1 text-base text-grey-700 leading-tight;
    }

    .actions {
      @apply mt-8 flex flex-col justify-center;

      & > div {
        @apply grid grid-cols-1 md:grid-cols-2 gap-4 justify-between mb-4 items-center;
      }
    }
  }
</style>
