<script>
  import Layout from '@/Layouts/Respond.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Label from "@/Components/Forms/Label.svelte";

  const form = useForm({
    reason: $page.props?.response_model?.reason ?? '',
    response_body: $page.props?.response_model?.body ?? ''
  }),

   handleSubmit = async (event) => {
    event.preventDefault();
    $form.post(`/app/respond/c/${$page.props?.campaign_link?.id}/respond/save`);
  }

  $: followUpQuestion = $page.props?.questions && $form.reason ? $page.props?.questions[$form.reason] : null;
  $: responseAllowed = $form.reason && $form.response_body.length > 20;
</script>

<Seo title="Review Pitch" />

<Layout>
  <div class="pitch">
    <h2>Review Pitch</h2>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <div class="body">{@html $page.props?.campaign_link?.pitch}</div>
  </div>

  <h2>How do you want to respond to {$page.props?.campaign_link?.seller?.name}</h2>
  <p>Try and give {$page.props?.campaign_link?.seller?.name} their
    <span>${$page.props?.campaign_link?.amount} worth</span> 😊</p>

  <form method="post" on:submit={(event) => handleSubmit(event)}>
    <div>
      <Select name="reason" label="Reason" size="sm"
              placeholder="Select a reason" options={$page.props.responses}
              bind:value={$form.reason}
              error={$form.errors.reason} />
    </div>

    {#if followUpQuestion}
      <div>
        <Label name="response_body" size="sm" label={followUpQuestion} />
        <Textarea name="response_body" size="sm" placeholder="Answer here..."
                  maxLength="1000" rows="6" bind:value={$form.response_body}
                  error={$form.errors.response_body} />
      </div>
    {/if}

    <div class="actions">
      <Button color="tertiary" type="button" size="sm" href="/app/respond/c/{$page.props?.campaign_link?.id}" dynamic>Cancel</Button>
      <Button color="secondary"
              disabled={!responseAllowed}
              loading={$form.processing}
              on:click={(event) => handleSubmit(event)}>Respond</Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  .pitch {
    @apply mb-8;

    .body {
      @apply text-sm mb-4 leading-4 border p-4 rounded-xl;
    }
  }

  h2 {
    @apply text-lg mb-2;
  }

  p {
    @apply text-sm;

    span {
      @apply text-violet font-semibold;
    }
  }

  form {
    @apply flex flex-col gap-4 mt-4;

    .actions {
      @apply flex gap-4 justify-end;
    }
  }
</style>
