<script>
  import {setContext} from 'svelte';
  import classNames from 'classnames';

  import Tag from './Tag.svelte';
  import Modal from './Modal.svelte';

  /** @type {import('@/Lib/types/component.d').TagSizeType} size */
  export let size = 'sm';
  export let limit = 3;

  /** @type {import('@/Lib/types/component.d').TagGroupItems} tags */
  export let tags;

  export let modalEnabled = true;

  $: othersTitle = tags.length > limit ? `${tags.length - limit} others` : ''
  setContext('group', size);

  let modalOpen = false;
  const viewTags = (event) => {
    event.preventDefault();
    modalOpen = true;
  }
</script>

<div {...$$restProps} class={classNames(size, $$props.class)} role="group">
  {#each tags.slice(0, limit) as tag}
    <Tag title={tag.title} href={tag.href} />
  {/each}
  {#if othersTitle && modalEnabled}
    <Tag on:click={viewTags} href="#"><strong>{othersTitle}</strong></Tag>
    <Modal bind:open={modalOpen}>
      <slot name="modal" />
      <div class="others">
        <div>
          {#each tags as tag}
            <Tag title={tag.title} href={tag.href} />
          {/each}
        </div>
      </div>
    </Modal>
  {/if}
</div>

<style lang="postcss">
  div[role="group"] {
    @apply inline-flex gap-x-2 gap-y-1.5 flex-wrap;
  }

  .others {
    @apply p-3 bg-grey-100 rounded-xl;

    & > div {
      @apply flex gap-2 flex-wrap max-h-[18.5rem] overflow-y-scroll overscroll-contain p-1;
    }
  }
</style>
