<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import classNames from "classnames";
  import 'intl-tel-input/build/css/intlTelInput.css';

  /** @type {string} name */
  export let name;

  /** @type {string | number} value */
  export let value;

  export let size = '';

  /** @type {string} inputClass */
  export let inputClass;

  export let loading = true;

  /** @type {boolean} disabled */
  export let disabled;

  export let error = '';
  export let description = '';

  /** @type {HTMLInputElement} element */
  let element, // Input element

   iti; // IntlTelInput instance

  const dispatch = createEventDispatcher()

  onMount(async () => {
    const  { default: intlTelInput } = await import('intl-tel-input/intlTelInputWithUtils');

    iti = intlTelInput(element, {
      excludeCountries: [],
      geoIpLookup: callback => {
        fetch('https://ipapi.co/json')
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback('us'));
      },
      initialCountry: 'auto',
      separateDialCode: true,
      strictMode: true,
    });

    iti.promise.then(() => {
      loading = false;
    });
  });

  const validate = () => {
    if (!iti.isValidNumber()) {
      // Const errorMap = ['Invalid phone number', 'Invalid country code', 'Phone number too short', 'Phone number too long', 'Invalid phone number'];
      // Const errorCode = iti.getValidationError();
      // Error = errorMap[errorCode] || 'Invalid number';
      return false;
    }

    error = '';
    return true;
  },

   setNumber = () => {
    if (!validate()) {
      dispatch('updateVal', { value: '' });
      return;
    }

    dispatch('updateVal', { value: iti.getNumber() });
  }
</script>

<div class={classNames('input', size)}>
  <div>
    <input
      type="tel"
      disabled={disabled || loading}
      {name}
      class={inputClass}
      bind:this={element}
      bind:value
      on:change={setNumber}
      on:click
      on:contextmenu
      on:focus
      on:input={setNumber}
      on:keydown
      on:keypress
      on:keyup
      on:mouseenter
      on:mouseleave
      on:mouseover
      on:paste
      {...$$restProps}
    />
    {#if loading}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  :global(.iti) {
    @apply block text-sm w-full;
  }

  .input {
    & > div {
     @apply relative w-full items-center;
    }

    &.sm {
      input {
        @apply py-2 text-sm !leading-none;
      }
    }
  }

  input {
    @apply border border-grey-200 rounded-lg px-2 py-3 text-grey-700 text-base !leading-none block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500;
  }

  svg {
    @apply block w-4 h-4 animate-spin absolute z-10 right-4 mt-1;
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
