<script>
  import Layout from "@/Layouts/Settings.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Credit from "@/Components/Shop/Credit.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Input from "@/Components/Forms/Input.svelte";

  const form = useForm({
    amount: null,
  }),
  handleSubmit = (event) => {
    event.preventDefault();
    $form.post('/app/settings/credits/convert');
  }
</script>

<Seo title="Credits" />

<Layout title="Credits">
  <p>You have <strong>${$page.props?.auth?.wallet?.allowed_total_formatted}</strong> available in your wallet.</p>
  <p>Use the form below to convert your wallet funds to Pitchfire credits.</p>
  <form on:submit={(e) => handleSubmit(e)} class="max-w-md">
    <Input
      loading={$form.processing}
      type="number"
      name="amount"
      size="xs"
      placeholder="Amount ($)"
      bind:value={$form.amount}
      error={$form.errors.amount}
    >
      <div slot="right">
        <Button loading={$form.processing} type="submit" size="xs" on:click={(e) => handleSubmit(e)}>Convert</Button>
      </div>
    </Input>
  </form>

  <div class="credit">
    <Credit credit={$page.props.credit_available}>
      <h3 class="mt-4 mb-0">Redeem Coupon</h3>
    </Credit>
  </div>
</Layout>

<style lang="postcss">
  .credit {
    @apply mt-8 max-w-2xl;
  }

  h3 {
    @apply text-base font-semibold text-blue-800;
  }

  p {
    @apply text-sm;
  }
</style>
