<script>
  import Layout from "@/Layouts/Page.svelte";
  import Works from '@/Components/Sections/Works.svelte';
  import SectionProfile from "@/Components/Sections/Profile.svelte";
  import FAQ from "@/Components/Sections/FAQ.svelte";

  export let profile;
</script>

<Layout nav footer>
  <section class="profile">
    <SectionProfile {profile} class="bg-blue-100 py-5 md:py-6 !px-6 lg:!px-10" />
    <slot />
  </section>

  <section id="how-it-works" class="how">
    <Works title="How does Pitchfire work?" />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  .profile {
    @apply overflow-hidden rounded-xl;
  }

  .how {
    border-top: 3px solid;
    @apply mt-12 max-w-5xl lg:px-12 mx-auto pt-12 border-white;
  }
</style>
