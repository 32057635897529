<script>
  import { inertia } from "@inertiajs/svelte";

  /** @type {import('@/Lib/types/component.d').Breadcrumbs} crumbs */
  export let crumbs = [];
</script>

<nav {...$$restProps}>
  <ul>
    {#each crumbs as crumb}
      {#if crumb.url}
        <li><a href={crumb.url} use:inertia>{crumb.title}</a><span></span></li>
      {:else}
        <li>{crumb.title}<span></span></li>
      {/if}
    {/each}
  </ul>
</nav>

<style lang="postcss">
  ul, li {
    @apply list-none m-0 p-0;
  }

  ul {
    @apply inline-block;
  }

  li {
    @apply text-sm inline-block text-black;

    a {
      @apply text-grey-600 no-underline;
    }

    span {
      @apply inline-block after:content-[">"] mx-2;
    }
  }

  li:last-of-type span {
    @apply after:content-none;
  }
</style>
