<script>
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconBurgerMenu} from "@/Components/Assets/Icons/index.js";
  import Profile from "@/Components/Elements/Profile.svelte";
  import {inertia, page} from "@inertiajs/svelte";

  let menuActive = false;
  const showMenu = () => {
    menuActive = !menuActive;
  }
</script>

<nav class="nav">
  <div>
    <button on:click|preventDefault={() => showMenu()}>
      <Icon data={IconBurgerMenu} size="xl" />
    </button>

    <ul class={menuActive ? 'open' : undefined}>
      <li><a href="https://meetings.hubspot.com/jeremy-leveille" rel="noopener noreferrer" target="_blank">Talk with Sales</a></li>
      {#if ! $page.props.auth?.loggedIn}<li><a href="/" use:inertia class="btn">Login</a></li>{/if}
    </ul>
  </div>

  {#if $page.props.auth?.loggedIn}
    <Profile
      href="/app/profile"
      name={$page.props.auth?.user?.name}
      image={$page.props.auth?.user?.avatar}
      imageAlt={$page.props.auth?.user?.name}
      subImage={$page.props.auth?.org?.logo}
      subImageAlt={$page.props.auth?.org?.name}
    />
  {/if}
</nav>

<style lang="postcss">
  .nav {
    @apply ml-auto mb-4 flex gap-2 lg:gap-8 lg:items-center;

    & > div {
      @apply text-right flex flex-col justify-center items-end relative;
    }

    button {
      @apply inline-block lg:hidden;
    }
  }

  ul, li {
    @apply list-none;
  }

  ul {
    @apply h-0 opacity-0 lg:w-auto lg:h-auto lg:opacity-100 lg:flex justify-evenly gap-12 items-center m-0 p-0;

    &.open {
      @apply w-auto h-auto transition-opacity opacity-100 absolute lg:relative lg:bg-transparent bg-blue/70 text-white lg:text-black top-12 lg:top-0 py-2 lg:py-0 px-6 lg:px-0;

      li {
        @apply block;
      }
    }

    li {
      @apply hidden lg:inline-block;

      a {
        @apply no-underline block lg:inline-block py-1 lg:py-0 whitespace-nowrap;

        @media screen(lg) {
          &.btn {
            @apply border-blue border hover:text-white hover:bg-blue focus:bg-blue text-blue px-4 md:px-12 py-1.5 rounded-lg;
          }
        }
      }
    }
  }
</style>
