<script>
  import Layout from "@/Layouts/App.svelte"
  import {inertia, page} from "@inertiajs/svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Pill from "@/Components/Elements/Pill.svelte";
  import InfiniteScroll from "@/Components/Layout/InfiniteScroll.svelte";
  import {writable} from "svelte/store";
  import axios from "axios";
  import TagGroup from "@/Components/Elements/TagGroup.svelte";
  import {buildDescription} from "@/Lib/profile";
  import {IconClose} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import cn from "classnames";

  let responded_active = true;

  if ($page.props?.pitch) {
    responded_active = $page.props?.pitch.status === 'Accepted' || $page.props?.pitch.status === 'Rejected';
  }

  /** @type Writable<{import('@/Lib/types/models.d').Pitch[]}> closedResponses */
  const closedResponses = writable($page.props?.closed_responses?.pages?.data),

  /** @type Writable<{import('@/Lib/types/models.d').Pitch[]}> pendingResponses */
   pendingResponses = writable($page.props?.pending_pitches?.pages?.data),

  /** @type Writable<{number}> closedPage */
   closedPage = writable(1),

  /** @type Writable<number> pendingPage */
   pendingPage = writable(1),

   toggleTabs = (value) => {
    responded_active = value;
  },

   updatePendingResults = async () => {
    await axios.post('/app/responses/list/pending', { pending_page: $pendingPage })
      .then((res) => {
        $pendingResponses = [...$pendingResponses, ...res.data.pages.data]
      });
  },

   updateClosedResults = async () => {
    await axios.post('/app/responses/list/closed', { closed_page: $closedPage })
      .then((res) => {
        $closedResponses = [...$closedResponses, ...res.data.pages]
      });
  },

   setPitchStatus = (status) => {
    if (status === 'Accepted') {
      return 'Interested';
    }
    else if (status === 'Rejected') {
      return 'Not Interested';
    }
    else if (status === 'Expired') {
      return 'Expired';
    }
    else if (status === 'Cancelled') {
      return 'Cancelled';
    }
  }

  let innerWidth = 0,
   panelClosed = true;

  $: isMobile = innerWidth < 768;

  const closePanel = () => {
    panelClosed = true;
  },
   openPanel = () => {
    panelClosed = false;
  }

  if ($page.url.startsWith('/app/responses/p/') || $page.url.startsWith('/app/responses/r/')) {
    openPanel();
  }
</script>

<svelte:window bind:innerWidth />

<Layout>
  <div class={cn('wrap', isMobile ? 'mobile' : '')}>
    <aside>
      <h1>Responses <span>({$page.props?.total ?? 0})</span></h1>

      <div class="tabs">
        <button type="button" class:active={responded_active} on:click={() => toggleTabs(true)}>
          Responded <span>({$page.props?.closed_responses?.total ?? 0})</span>
        </button>
        <button type="button" class:active={!responded_active} on:click={() => toggleTabs(false)}>
          Awaiting <span>({$page.props?.pending_pitches?.total ?? 0})</span>
        </button>
      </div>

      {#if responded_active}
        <div class="pitches">
          {#if $closedResponses?.length > 0}
            {#each $closedResponses as response}
              <a
                use:inertia={{ only: ['response'] }}
                href="/app/responses/r/{response?.id}"
                class={$page.props?.response?.id === response?.id && 'active'}
                on:click={() => openPanel()}
              >
                <Profile
                  size="sm"
                  title={response?.user?.name}
                  image={response?.user?.avatar}
                  imageAlt={response?.user?.name}
                  subImage={response?.user?.org_logo}
                  subImageAlt={response?.user?.org_name}
                  subTitleHtml={buildDescription(response?.user?.job_level, response?.user?.org_name, response?.user?.department, false)}
                />
                <span>
                  <span class="amount">${response?.fee}</span>
                  <Pill
                    color={response?.status === 'Accepted' ? 'green' : 'red'}
                    title={setPitchStatus(response?.status)}
                  />
                </span>
              </a>
            {/each}

            <InfiniteScroll
              hasMore={$closedResponses.length !== $page.props?.closed_responses?.total}
              threshold={100}
              on:loadMore={() => {$closedPage++; updateClosedResults()}}
            />
          {:else}
            <p>No responses found</p>
          {/if}
        </div>
      {/if}

      {#if !responded_active}
        <div class="pitches">
          {#if $pendingResponses?.length > 0}
            {#each $pendingResponses as pitch}
              <a
                use:inertia={{ only: ['pitch'] }}
                href="/app/responses/p/{pitch?.id}"
                class={$page.props?.pitch?.id === pitch?.id && 'active'}
                on:click={() => openPanel()}
              >
                <Profile
                  size="sm"
                  title={pitch?.buyer?.name}
                  image={pitch?.buyer?.avatar}
                  imageAlt={pitch?.buyer?.name}
                  subImage={pitch?.buyer?.org_logo}
                  subImageAlt={pitch?.buyer?.org_name}
                  subTitleHtml={buildDescription(pitch?.buyer?.job_title, pitch?.buyer?.org_name, pitch?.buyer?.department, false)}
                />
                <span>
                  <span class="amount">${pitch.request_fee}</span>
                </span>
              </a>
            {/each}

            <InfiniteScroll
              hasMore={$pendingResponses?.length !== $page
              .props?.pending_pitches?.total}
              threshold={100}
              on:loadMore={() => {$pendingPage++; updatePendingResults()}}
            />

          {:else}
            <p>No pending responses found</p>
          {/if}
        </div>
      {/if}
    </aside>

    <div class={cn('content', isMobile && panelClosed ? 'closed' : '')}>
      <button type="button" class:active={isMobile} on:click={() => closePanel()}>
        <Icon data={IconClose} size="lg" />
      </button>

      {#if $page.props?.response}
        <div>
          <div class="header">
            <div class="buyer">
              <Profile
                title={$page.props?.response?.user?.name}
                href="/@{$page.props?.response?.user?.slug}"
                image={$page.props?.response?.user?.avatar}
                imageAlt={$page.props?.response?.user?.name}
                subImage={$page.props?.response?.user?.org_logo}
                subImageAlt={$page.props?.response?.user?.org_name}
                subTitleHtml={$page.props?.response?.user?.description}
              >
                {#if $page.props?.pitch?.buyer_focuses}
                  <TagGroup tags={$page.props?.pitch?.buyer_focuses} />
                {/if}
              </Profile>
            </div>

            <div class="meta">
              <div class="amount">${$page.props?.response?.fee}</div>
              <div class="created"><strong>Created:</strong> {$page.props?.response?.created_at}</div>
            </div>
          </div>

          <slot />
        </div>
      {/if}

      {#if $page.props?.pitch}
        <div>
          <div class="header">
            <div class="buyer">
              <Profile
                title={$page.props?.pitch?.buyer?.name}
                href="/@{$page.props?.pitch?.buyer?.slug}"
                image={$page.props?.pitch?.buyer?.avatar}
                imageAlt={$page.props?.pitch?.buyer?.name}
                subImage={$page.props?.pitch?.buyer?.org_logo}
                subImageAlt={$page.props?.pitch?.buyer?.org_name}
                subTitleHtml={buildDescription($page.props?.pitch?.buyer?.job_level, $page.props?.pitch?.buyer?.org_name, $page.props?.pitch?.buyer?.department, $page.props?.pitch?.buyer?.focuses)}
              >
                {#if $page.props?.pitch?.buyer?.tags}
                  <TagGroup tags={$page.props?.pitch?.buyer?.tags} />
                {/if}
              </Profile>
            </div>

            <div class="meta">
              <div class="amount">${$page.props?.pitch?.request_fee}</div>
              <div class="created"><strong>Created:</strong> {$page.props?.pitch?.created_at}</div>
              {#if $page.props?.pitch?.expires_at}
                <div class="expires"><strong>Expires:</strong> {$page.props?.pitch?.expires_at}</div>
              {/if}
            </div>
          </div>

          <slot />
        </div>
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply flex-1 md:flex min-h-full;

    .content > button {
      @apply hidden;
    }

    &.mobile {
      @apply relative;

      .pitches {
        @apply pb-[100%];
      }

      .content {
        @apply fixed z-10 top-1/2 right-0 w-full bg-white shadow-2xl shadow-black h-full;

        &.closed {
          @apply opacity-0 -z-10;
        }

        & > button {
          @apply block absolute top-3 left-2 z-10;
        }

        & > div {
          @apply overflow-scroll h-1/2 pb-8;
        }
      }
    }
  }

  h1 {
    @apply text-2xl mb-8 ml-4;

    span {
      @apply text-grey-500;
    }
  }

  aside {
    @apply md:w-80 md:shrink-0 py-8 lg:py-6 bg-grey-100 min-h-screen;
  }

  .tabs {
    @apply bg-blue-50 rounded-lg p-1 flex mx-3 md:mx-2;

    button {
      @apply inline-block rounded-lg w-1/2 py-2.5 text-sm font-semibold transition-colors duration-300;

      &.active {
        @apply bg-blue text-white;

        &:hover {
          @apply bg-blue text-white;
        }

        span {
          @apply text-white;
        }
      }

      &:hover {
        @apply bg-blue-100 text-black;
      }

      span {
        @apply text-black;
      }
    }
  }

  .pitches {
    @apply flex flex-col gap-2 mt-2 px-4 md:px-2 text-center md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-250px)] lg:max-h-[calc(100vh-160px)];

    a {
      @apply w-full px-2 py-3 border-b border-grey-200 flex flex-row md:flex-col lg:flex-row justify-between gap-2 no-underline;

      &:hover,
      &.active {
        @apply bg-white/50 rounded-lg border-grey-100;
      }

      &.active {
        @apply bg-white;
      }

      & > span {
        @apply flex flex-col items-end;
      }

      .amount {
        @apply text-violet text-xl font-semibold mb-2;
      }
    }
  }

  .content {
    @apply w-full md:border-l border-grey-200 px-6 py-8 lg:py-6 flex flex-col md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-90px)] lg:max-h-screen;

    .header {
      @apply flex justify-between gap-4 pb-8 border-b border-grey-200 mb-6;
    }

    .meta {
      @apply flex flex-col items-end justify-between;
    }

    .created {
      @apply text-xs text-right mt-3 text-grey-600;
    }

    .expires {
      @apply text-xs text-right mt-1 text-red-400;
    }

    .amount {
      @apply text-violet font-semibold text-3xl;
    }

    p {
      span {
        @apply text-violet font-semibold;
      }
    }
  }
</style>
