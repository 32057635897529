<script>
  import Layout from "@/Layouts/Response.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte"
</script>

<Seo title={`$${$page.props?.pitch?.request_fee} Pitch to ${$page.props?.pitch?.buyer?.name}`} />

<Layout>
  <div class="pitch">
    <div class="title">Review Your Pitch to {$page.props?.pitch?.buyer?.name}</div>
    <div class="body">{$page.props?.pitch?.body}</div>
  </div>

  {#if $page.props?.pitch?.meeting_link}
    <p><a href={$page.props?.pitch?.meeting_link} target="_blank" rel="noopener noreferrer">Meeting Link</a></p>
  {/if}

  {#if $page.props?.pitch?.status === 'Pending'}
    <div class="no-response">{$page.props?.pitch?.buyer?.name} has not responded yet</div>
  {:else if $page.props?.pitch?.status === 'Expired'}
    <div class="no-response">{$page.props?.pitch?.buyer?.name} did not respond to your pitch in time</div>
  {:else if $page.props?.pitch?.status === 'Cancelled'}
    <div class="no-response">This pitch has been cancelled</div>
  {/if}
</Layout>

<style lang="postcss">
  .pitch {
    @apply flex flex-col relative mb-4;

    .title {
      @apply font-semibold text-lg mb-2;
    }

    .body {
      @apply whitespace-pre-line text-sm border p-4 rounded-xl;
    }
  }

  .no-response {
    @apply rounded-lg bg-red-100 text-red-800 font-semibold px-2.5 py-1.5 mt-4 text-sm;
  }
</style>
