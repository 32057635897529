<script>
  import { inertia, page } from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconGoogleMeet, IconMicrosoftTeams, IconTick, IconZoom} from "@/Components/Assets/Icons/index.js";
  import Input from "@/Components/Forms/Input.svelte";

  export let form;

  export let callback = () => {};
</script>

<div class="pitch">
  <div class="head">
    <div>
      <div class="step">
        <Icon data={IconTick} size="lg" class="fill-white" />
      </div>
      <p>Write your pitch</p>
    </div>

    <span class="ellipsis"></span>

    <div>
      <div class="step active">2</div>
      <p>Make an offer</p>
    </div>

    <span class="ellipsis"></span>
    <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
  </div>

  <form method="post" on:submit|preventDefault={(event) => callback(event)}>
    <div class="description">
      <h3>Meeting Link</h3>
      <p>Add your meetings / video conference link below.</p>
      <p>Your meeting link will be added to the calendar event on accepted pitches.</p>
    </div>

    <div class="field">
      <Input
        type="url"
        name="meeting_link"
        placeholder="Video conference link (Zoom, Google Meet, Microsoft Teams...)"
        size="sm"
        wrapperClass="max-w-lg"
        bind:value={$form.meeting_link}
        error={$form.errors?.meeting_link}
      />
    </div>

    <div class="icons">
      <Icon data={IconZoom} size="xxl" />
      <Icon data={IconGoogleMeet} size="xxl" />
      <Icon data={IconMicrosoftTeams} size="xxl" />
    </div>

    <div class="actions">
      <Button
        dynamic
        color="tertiary"
        size="sm"
        href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
      >Back</Button>

      <Button type="submit" class="!px-8" color="red" size="sm" on:click={(event) => callback(event)}>Next</Button>
    </div>
  </form>

  <div class="step-indicator">
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
       class="dot" use:inertia={{preserveScroll: true}}
    >
    </a>
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
       class="dot" use:inertia={{preserveScroll: true}}
    >
    </a>
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/meeting-link` : `/@${$page.props?.profile?.slug}/meeting-link`}
       class="dot active" use:inertia={{preserveScroll: true}}
    >
    </a>
    <div class="dot"></div>
  </div>
</div>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;

        &.active {
          @apply bg-red-500;
        }
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }
  }

  form {
    @apply w-9/12 mx-auto bg-white rounded-lg p-6;

    h3 {
      @apply text-lg;
    }

    p {
      @apply text-grey-600 text-sm my-2;
    }
  }

  .icons {
    @apply flex gap-2 items-center mt-2;
  }

  .actions {
    @apply flex justify-between items-center mt-4;
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
