import api from "@/Lib/api.js";

const sendMessageToExtension = (action, data = {}) => {
  window.postMessage({
    source: 'WEB_PAGE',
    target: 'BROWSER_EXTENSION',
    action: action,
    data: data
  }, import.meta.env.VITE_APP_URL);
}

/**
 * Listens for events from the extension.
 * @param {*} event
 */
const listenForMessageFromExtension = async ( event ) => {

  // Ensure the message is from the browser
  if( event.source !== window ){
    return;
  }

  // Ensure the message is from our app
  if( event.origin !== import.meta.env.VITE_APP_URL ){
    return;
  }

  let message = event.data;

  if( typeof message !== 'object'
    || message === null
    || message.source !== 'BROWSER_EXTENSION'
    || message.target !== 'WEB_PAGE' ){
    return;
  }

  try {
    switch (message.action) {
      case 'REQUEST_TOKEN': {
        const response = await api.post(`/auth/retrieve-token`);
        sendMessageToExtension('USER_AUTHENTICATED', response?.data);
        break;
      }
    }
  } catch (error) {
    console.error('Request failed', { cause: error?.message });
  }
}

export { listenForMessageFromExtension, sendMessageToExtension }
