<script>
  // Adapted from https://github.com/rodneylab/sveltekit-blog-mdx/blob/main/src/lib/components/SEO/SchemaOrg.svelte

  import hash from 'object-hash';

  export let article = false;
  export let author;

  /**
   * @type {{ name: string; slug: string }[]}
   */
  export let breadcrumbs;

  export let datePublished;
  export let entity;
  export let entityLegal;
  export let contactEmail;
  export let description;
  export let lastUpdated;
  export let featuredImage;
  export let metaDescription;
  export let siteLanguage;
  export let siteTitle;
  export let siteTitleAlt;
  export let appUrl;
  export let siteUrl;
  export let title;
  export let url;
  export let facebookPage;
  export let instagramProfile;
  export let linkedinProfile;
  export let twitterUsername;

  /**
   * @type {{ url: string; faviconWidth: number; faviconHeight: number } | null}
   */
  export let entityMeta = null;

  const entityHash = hash({author}, {algorithm: 'md5'}),

   schemaOrgArray = [],

   schemaOrgPublisher = {
    '@type': 'Organization',
    '@id': `${appUrl}/#/schema/publisher`,
    name: entity,
    legalName: entityLegal,
    description,
    url: siteUrl,
    email: contactEmail,
    foundingDate: '2021',
    logo: {
      '@type': 'ImageObject',
      inLanguage: siteLanguage,
      url: `${appUrl}/assets/icon.png`,
      contentUrl: `${appUrl}/assets/icon.png`,
      width: 512,
      height: 512,
      caption: entity,
    },
    contactPoint: {
      "@type": "ContactPoint",
      email: contactEmail,
    },
    sameAs: [
      `https://twitter.com/${twitterUsername}`,
      linkedinProfile,
      facebookPage,
      instagramProfile,
    ],
  };
  schemaOrgArray.push(schemaOrgPublisher);

  const schemaOrgWebsite = {
    '@type': 'WebSite',
    '@id': `${appUrl}/#/schema/website`,
    url: appUrl,
    name: siteTitle,
    description: siteTitleAlt,
    publisher: {
      '@id': `${appUrl}/#/schema/publisher`,
    },
    inLanguage: siteLanguage,
  };
  schemaOrgArray.push(schemaOrgWebsite);

  const schemaOrgBreadcrumbList = breadcrumbs?.length > 0 ? {
    '@type': 'BreadcrumbList',
    '@id': `${url}#breadcrumb`,
    itemListElement: breadcrumbs.map((element, index) => {
      const crumb = {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Thing',
          name: element.name,
        }
      };
      if (element.slug) {
        crumb.item['@type'] = 'WebPage';
        crumb.item['@id'] = `${appUrl}${element.slug}`;
        crumb.item.url = `${appUrl}${element.slug}`;
      }
      return crumb;
    }),
  } : {};
  if (breadcrumbs?.length > 0) {
    schemaOrgArray.push(schemaOrgBreadcrumbList);
  }

  let primaryImage = null;
  if (featuredImage) {
    primaryImage = {
      '@type': 'ImageObject',
      '@id': `${url}#primaryimage`,
      inLanguage: siteLanguage,
      url: featuredImage.url,
      contentUrl: featuredImage.url,
      width: featuredImage.width,
      height: featuredImage.height,
      caption: featuredImage.caption,
    }
  }

  const schemaOrgWebPage = {
    '@type': 'WebPage',
    '@id': url,
    url,
    name: title,
    isPartOf: {
      '@id': `${appUrl}/#/schema/website`
    },
    datePublished,
    dateModified: lastUpdated,
    publisher: {
      '@id': `${appUrl}/#/schema/publisher`,
    },
    description: metaDescription,
    inLanguage: siteLanguage,
    potentialAction: [{
      '@type': 'ReadAction',
      target: [url],
    }],
  };

  if (primaryImage) {
    schemaOrgWebPage.primaryImageOfPage = primaryImage;
  }

  schemaOrgArray.push(schemaOrgWebPage);

  if (article) {
    const schemaOrgArticle = {
      '@type': 'Article',
      author: {
        '@id': `${appUrl}/#/schema/person/${entityHash}`,
      },
      headline: title,
      datePublished,
      dateModified: lastUpdated,
      isPartOf: {
        '@id': url,
      },
      mainEntityOfPage: {
        '@id': url,
      },
      publisher: {
        '@id': `${appUrl}/#/schema/publisher`,
      },
      articleSection: ['blog'],
      inLanguage: siteLanguage,
    };

    if (primaryImage) {
      schemaOrgArticle.primaryImageOfPage = primaryImage;
    }
    schemaOrgArticle.push(schemaOrgArticle);
  }

  if (entityMeta !== null) {
    schemaOrgArray.push(entityMeta);
  }

  const schemaOrgObject = {
    '@context': 'https://schema.org',
    '@graph': schemaOrgArray,
  },
   jsonLdString = JSON.stringify(schemaOrgObject),
   jsonLdScript = `
		<script type="application/ld+json">
			${jsonLdString}
		${'<'}/script>
	`;
</script>

<svelte:head>
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  {@html jsonLdScript}
</svelte:head>
