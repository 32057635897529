<script>
  import {setContext} from 'svelte';
  import classNames from 'classnames';

  /** @type {import('@/Lib/types/component.d').SizeType} size */
  export let size = 'md';

  /** @type {string} btnGroupClass */
  export let btnGroupClass;
  let buttonClass;
  $: buttonClass = btnGroupClass ?? classNames('group', size, $$props.class);

  setContext('group', size);
</script>

<div {...$$restProps} class={buttonClass} role="group">
  <slot />
</div>

<style lang="postcss">
  .group {
    @apply inline-flex rounded-lg overflow-hidden;
    gap: 1px;

    &.xxs {
      @apply rounded-full;
    }
  }
</style>
