<script>
  import Layout from '@/Layouts/User.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import PitchLink from "@/Components/Sections/Pitch/Link.svelte";

  const form = useForm({
      meeting_link: $page.props?.auth?.user?.meeting_link
  }),

   handleSubmit = (event) => {
    event.preventDefault();
    $form.post(`/pitch/${$page.props?.pitch?.id}/meeting-link/save`, {
      preserveState: true,
      preserveScroll: true,
    })
  }
</script>

<Seo title={`Pitch ${$page.props.profile.name}`} />

<Layout profile={$page.props?.profile}>
  <PitchLink
    previous="/pitch/${$page.props?.pitch?.id}/set-rate"
    callback={handleSubmit}
    {form}
  />
</Layout>
