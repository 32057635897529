<script>
  // Adapted from https://github.com/rodneylab/sveltekit-blog-mdx/blob/main/src/lib/components/SEO/index.svelte

  import {page} from '@inertiajs/svelte'
  import website from '@/Lib/config';
  import Twitter from "./Twitter.svelte";
  import OpenGraph from "./OpenGraph.svelte";
  import SchemaOrg from "./SchemaOrg.svelte";

  const defaultFeaturedImage = `${$page.props.common.app_url}/assets/defaultFeatured.jpg`,
   defaultOgImage = `${$page.props.common.app_url}/assets/opengraph.jpg`,
   defaultTwitterImage = `${$page.props.common.app_url}/assets/opengraph.jpg`,

   {
    author,
    contactEmail,
    entity,
    entityLegal,
    description,
    facebookAuthorPage,
    facebookPage,
    instagramProfile,
    ogLanguage,
    siteLanguage,
    siteShortTitle,
    siteTitle,
    siteUrl,
    linkedinProfile,
    twitterUsername,
  } = website;

  export let article = false;
  export let breadcrumbs = [];
  export let entityMeta = null;
  export let lastUpdated;
  export let datePublished;
  export let metaDescription = description;
  export let timeToRead = 0;
  export let title;

  const defaultAlt = 'Pitchfire';

  // Imported props with fallback defaults
  export let featuredImage = defaultFeaturedImage ? {
    url: defaultFeaturedImage,
    alt: defaultAlt,
    width: 672,
    height: 448,
    caption: 'Home page',
  } : undefined;
  export let ogImage = defaultOgImage ? {
    url: defaultOgImage,
    alt: defaultAlt,
  } : undefined;
  export let twitterImage = defaultTwitterImage ? {
    url: defaultTwitterImage,
    alt: defaultAlt,
  } : undefined;

  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle,
   url = $page.props.common.url.current,
   openGraphProps = {
    article,
    datePublished,
    lastUpdated,
    image: ogImage,
    metaDescription,
    ogLanguage,
    pageTitle,
    siteTitle,
    url,
    ...(article ? {datePublished, lastUpdated, facebookPage, facebookAuthorPage} : {}),
  },

   schemaOrgProps = {
    article,
    appUrl: $page.props.common.app_url,
    author,
    breadcrumbs,
    datePublished,
    entity,
    entityLegal,
    description,
    lastUpdated,
    entityMeta,
    featuredImage,
    metaDescription,
    siteLanguage,
    siteTitle,
    siteTitleAlt: siteShortTitle,
    contactEmail,
    siteUrl,
    title: pageTitle,
    url,
    facebookPage,
    instagramProfile,
    linkedinProfile,
    twitterUsername,
  },

   twitterProps = {
    article,
    author,
    twitterUsername,
    image: twitterImage,
    timeToRead,
  };
</script>

<svelte:head>
  <title>{pageTitle}</title>
  <meta name="description" content={metaDescription} />
  <meta
    name="robots"
    content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  />
  <link rel="canonical" href={url} />
</svelte:head>
<Twitter {...twitterProps} />
<OpenGraph {...openGraphProps} />
<SchemaOrg {...schemaOrgProps} />
