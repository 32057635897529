import {writable} from 'svelte/store'

export const isSideMenuCollapsed = writable(true)

export const mainMenu = writable([])

export const closeSideMenu = () => {
  isSideMenuCollapsed.set(true)
}

export const openSideMenu = () => {
  isSideMenuCollapsed.set(false)
}
