<script>
  import classNames from 'classnames';
  import Icon from '../Assets/Icon.svelte';
  import Close from '../Assets/Icons/close.svg?raw';

  /** @type {string} href */
  export let href;

  /** @type {string} name */
  export let name;

  /** @type {boolean} disabled */
  export let disabled;
</script>

<svelte:element
  {...$$restProps}
  aria-label={name}
  class={classNames('ml-auto p-0.5', $$props.class)}
  {disabled}
  on:change
  on:click
  on:keydown
  on:keyup
  on:mouseenter
  on:mouseleave
  role="button"
  tabindex="0"
  this={href ? 'a' : 'button'}
  type={href ? undefined : 'button'}
>
  {#if name}<span class="sr-only">{name}</span>{/if}
  <Icon class="fill-grey-400" data={Close} size="lg" />
</svelte:element>
