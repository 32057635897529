<script>
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Layout from "@/Layouts/App.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import copy from "@/Lib/utils/copy";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconCopy} from "@/Components/Assets/Icons/index.js";
  import Alert from "@/Components/Elements/Alert.svelte";
  import {page} from "@inertiajs/svelte";
  import Pill from "@/Components/Elements/Pill.svelte";

  const getStatusColor = (status) => {
    switch (status) {
      case 'Draft':
        return 'grey';
      case 'Active':
        return 'green';
      case 'Archived':
        return 'info';
      case 'Paused':
        return 'yellow';
    }
  }
</script>

<Seo title="Campaign Links" />

<Layout>
  <div class="wrap">
    <aside>
      <h1>Campaign Links</h1>

      <div>
        <Breadcrumb crumbs={[
            { title: 'Menu', url: '/' },
            { title: 'Campaign Links', url: '/app/campaign-links' }
        ]} />

        <Button type="button" color="tertiary" size="xs" class="w-full mt-4 mb-8" href="/app/campaign-links/create" dynamic>Create New Link</Button>

        {#if $page.props.links?.length > 0}
          <div class="links">
            {#each $page.props.links as link}
              <div>
                <div class="row">
                  <div class="title">{link.title}</div>
                  <div class="status">
                    <Pill
                      color={getStatusColor(link.status)}
                      size="xs"
                      title={link.status}
                      dynamic />
                  </div>
                </div>

                <div class="row">
                  <button class="url" on:click|preventDefault={copy(link.url)}>
                    <span>{link.url.replace('https://', '')}</span>
                    <Icon data={IconCopy} size="md" />
                  </button>

                  <div>
                    <Button type="button" color="primary" size="xxs" href="/app/campaign-links/{link.id}/view">
                      View
                    </Button>
                    <Button type="button" color="tertiary" size="xxs" href="/app/campaign-links/{link.id}/edit">
                      Edit
                    </Button>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        {:else}
          <Alert type="info" message="You haven't created any campaign links" />
        {/if}
      </div>
    </aside>

    <div class="content">
      <slot />
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply flex-1 md:flex min-h-screen;
  }

  aside {
    @apply md:w-80 md:shrink-0 py-8 lg:py-6 bg-grey-100;

    & > div {
      @apply mx-4;
    }
  }

  .links {
    @apply pt-4 flex flex-col gap-4;

    & > div {
      @apply border-b border-grey-200 pb-4;

      &:last-child {
        @apply border-0;
      }
    }

    .row {
      @apply flex justify-between items-center mb-2;
    }

    .title {
      @apply font-semibold text-base;
    }

    .url {
      @apply flex justify-start items-center text-xs gap-2;

      span {
        @apply text-violet
      }
    }
  }

  .content {
    @apply w-full md:border-l border-grey-200 px-6 py-8 lg:py-24 flex flex-col md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-90px)] lg:max-h-screen;
  }

  h1 {
    @apply text-3xl md:text-4xl mb-8 ml-4;
  }
</style>
