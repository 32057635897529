<script>
  import classNames from "classnames";
  import {onMount} from "svelte";

  /** @type {import('@/Lib/types/component.d').ProfileType} theme */
  export let theme = 'plain';
  export let href = '';

  /** @type {string} image */
  export let image;

  /** @type {string} imageAlt */
  export let imageAlt;
  export let subImage = '';
  export let subImageAlt = '';
  export let title = '';
  export let titleElement = 'div';
  export let subTitle = '';
  export let subTitleElement = 'div';
  export let subTitleHtml = '';

  /** @type {import('@/Lib/types/component.d').ProfileSizeType} size */
  export let size = 'md';

  let isExternalImage = false,
   isExternalSubImage = false;

  onMount(() => {
    const isExternalURL = (url) => new URL(url).origin !== location.origin;
    if (image && isExternalURL(image)) {
      isExternalImage = true;
    }

    if (subImage && isExternalURL(subImage)) {
      isExternalSubImage = true;
    }
  });
</script>

<svelte:element class={classNames($$props.class, 'profile', size, theme, (title || $$slots) && 'with-body')} {href}
                this={href ? 'a' : 'div'} role={href ? 'link' : undefined}>
  {#if subImage && image}
    <div class="image">
      <img src={image} alt={imageAlt} referrerpolicy={isExternalImage ? 'no-referrer' : 'origin'} />
      <img src={subImage} class="sub" alt={subImageAlt} referrerpolicy={isExternalSubImage ? 'no-referrer' : 'origin'} />
    </div>
  {:else if image}
    <img src={image} alt={imageAlt} referrerpolicy={isExternalImage ? 'no-referrer' : 'origin'} />
  {/if}

  {#if title || $$slots}
    <div>
      {#if title}
        <svelte:element this={titleElement} class="title">{title}</svelte:element>
      {/if}

      {#if subTitle || subTitleHtml}
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <svelte:element this={subTitleElement} class="subtitle">{subTitle}{@html subTitleHtml}</svelte:element>
      {/if}

      <slot />
    </div>
  {/if}

  {#if $$slots.actions}
    <div class="actions">
      <slot name="actions" />
    </div>
  {/if}
</svelte:element>

<style lang="postcss">
  a.profile {
    @apply cursor-pointer no-underline;

    &:hover {
      @apply shadow-none;
    }
  }

  .profile {
    &.border {
      @apply px-4 py-3 bg-grey-100 border-b border-grey-300 rounded-2xl shadow-sm;
    }

    &.minimal {
      @apply p-6 bg-grey-100 rounded-2xl shadow-sm;
    }

    &.with-body {
      @apply flex flex-col sm:flex-row gap-4 sm:items-start;

      &.sm {
        @apply gap-2;
      }
    }

    img {
      @apply inline-block h-12 w-12 rounded-full object-cover;
    }

    .image {
      @apply relative inline-block pb-1 pr-1 flex-none w-fit;

      img:first-child {
        @apply h-12 w-12 rounded-full;
      }

      .sub {
        @apply absolute -right-0.5 -bottom-0.5 block h-6 w-6 rounded-full shadow-md;
      }
    }

    .title {
      @apply mb-1 text-base font-semibold text-left;
    }

    .subtitle {
      @apply mb-1 text-grey-600 text-xs text-left;
    }

    .actions {
      @apply ml-auto;
    }

    &.sm {
      img {
        @apply h-9 w-9;
      }

      .image {
        @apply pb-1 pr-1;

        img:first-child {
          @apply h-9 w-9;
        }

        .sub {
          @apply h-5 w-5;
        }
      }

      .title {
        @apply text-sm mb-0.5;
      }

      .subtitle {
        @apply text-xs mb-0;
      }
    }

    &.lg {
      img {
        @apply h-14 w-14;
      }

      .image {
        img:first-child {
          @apply h-14 w-14;
        }

        .sub {
          @apply h-7 w-7;
        }
      }

      .title {
        @apply text-base mb-0.5;
      }

      .subtitle {
        @apply text-sm mb-0;
      }
    }

    &.xl {
      img {
        @apply h-16 w-16;
      }

      .image {
        img:first-child {
          @apply h-16 w-16;
        }

        .sub {
          @apply h-8 w-8;
        }
      }

      .title {
        @apply text-xl mb-0.5;
      }

      .subtitle {
        @apply text-xs mb-2;
      }
    }
  }
</style>
