<script>
  // Adapted from https://github.com/rodneylab/sveltekit-blog-mdx/blob/main/src/lib/components/SEO/Twitter.svelte

  export let article = false;
  export let author;
  export let twitterUsername;
  export let image;
  export let timeToRead = 0;

  /*
   * When there is an equivalent og tag present, Twitter takes that so check OpenGraph before
   * adding additional tags, unless you want to override OpenGraph.
   */
</script>

<svelte:head>
  <meta content="summary_large_image" name="twitter:card" />
  {#if image}
    <meta name="twitter:image" content={image.url} />
  {/if}
  {#if twitterUsername}
    <meta name="twitter:creator" content={`@${twitterUsername}`} />
    <meta name="twitter:site" content={`@${twitterUsername}`} />
  {/if}
  <meta content="Written by" name="twitter:label1" />
  <meta content={author} name="twitter:data1" />
  {#if article && timeToRead > 0}
    <meta name="twitter:label2" content="Est. reading time" />
    <meta name="twitter:data2" content={timeToRead !== 1 ? `${timeToRead} minutes` : '1 minute'} />
  {/if}
</svelte:head>
