<script>
  import { onDestroy, onMount } from "svelte";
  import classNames from "classnames";
  import { stripHtml } from "string-strip-html";

  /** @type {ClassicEditor}  */
  let editor;

  /** @type {string} name */
  export let name;
  export let label = '';
  export let description = '';

  /** @type {string} value */
  export let value = "";

  /** @type {boolean} disabled */
  export let disabled;

  export let placeholder = "Type your text here...";
  export let loading = true;

  /**
   * @typedef {'top' | 'inline'} Position
   */

  /** @satisfies Position */
  export let labelPosition = 'top';
  export let maxLength = 255;

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size;

  export let error = '';

  export let layout = 'simple';

  // Initialize CKEditor when the component is mounted
  onMount(async () => {
    let toolbarItems = [
      'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo', '|', 'link'
    ];

    if (layout === 'full') {
      toolbarItems = [
        'heading', '|',
        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', '|',
        'bulletedList', 'numberedList', '|',
        'outdent', 'indent', '|',
        'undo', 'redo', '|',
        'fontSize', 'fontFamily', 'alignment', '|',
        'link', 'specialCharacters', 'blockQuote', '|',
      ]
    }

    const { default: ClassicEditor } = await import('@ckeditor/ckeditor5-build-classic');

    ClassicEditor.create(document.querySelector("#editor"), {
        toolbar: {
          items: toolbarItems,
          shouldNotGroupWhenFull: true
        },
      })
      .then((newEditor) => {
        editor = newEditor;
        editor.setData(value);
        editor.model.document.on("change:data", () => {
          value = editor.getData();
        });
        loading = false;
      })
      .catch((error) => {
        console.error(error);
        loading = false;
      });
  });

  // Destroy CKEditor instance when the component is destroyed
  onDestroy(() => {
    if (editor) {
      editor.destroy();
    }
  });

  $: hasError = value.length > maxLength || error;
  $: valueLength = stripHtml(value).result.length;
</script>

<div class={classNames('input', size, `label-${labelPosition}`, hasError && 'error')}>
  {#if label}
    <label for={name}>{label}</label>
  {/if}

  <div class="input-wrapper">
    <textarea
      {...$$restProps}
      id="editor"
      {name}
      bind:value
      {placeholder}
      disabled={disabled || loading}
      class="ck-content"
    ></textarea>

    {#if maxLength}
      <div class={classNames('counter', !value || valueLength <= maxLength ? '' : 'error')}>
        <span>{valueLength ?? 0} / {maxLength}</span>
      </div>
    {/if}

    {#if loading}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  :global(.ck-content) {
    @apply text-sm;
  }

  :global(.ck-editor__editable_inline:not(.ck-comment__input *)) {
    min-height: 150px;
    overflow-y: auto;
  }

  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700;
    }

    & > div {
      @apply relative w-full items-center;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    &.error {
      textarea {
        @apply border-red border-2 focus:border-red;
      }

      label,
      .counter {
        @apply text-red;
      }
    }

    textarea {
      @apply border border-grey-200 rounded-lg px-2 py-3 text-grey-700 text-base !leading-none block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500 resize-none box-border;
    }

    .counter {
      @apply text-xs text-right text-grey-500 bg-white/80 my-1.5 px-1;
    }

    svg {
      @apply block w-4 h-4 animate-spin absolute z-10 right-4;
    }

    &.xs {
      label {
        @apply text-xs;
      }

      textarea {
        @apply py-2 text-xs !leading-none;
      }
    }

    &.sm {
      label {
        @apply text-sm;
      }

      textarea {
        @apply py-2 text-sm !leading-none;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }

      textarea {
        @apply py-3.5 text-lg !leading-none;
      }
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
