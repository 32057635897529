<script>
  import Layout from '@/Layouts/Public.svelte';
  import {onMount} from "svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import Logo from "@/Components/Assets/Logo.svelte";
  import api from "@/Lib/api.js";
  import Button from "@/Components/Buttons/Button.svelte";
  import {sendMessageToExtension} from "@/Lib/extension.js";

  let processing = true;
  let loggedIn = false;

  onMount(async () => {
    try {
      const request = await api.post(`/auth/authenticated`);
      if (request.status !== 200) {
        processing = false;
        console.error('failed to request authentication status')
      } else {
        processing = false;

        if (request?.data?.loggedIn) {
          loggedIn = true;
          sendMessageToExtension('CLOSE_AUTH_WINDOW');
        }
      }
    } catch (error) {
      processing = false;
      console.error(error?.message)
    }
  })
</script>

<Layout class="flex-col gap-8">
  <Logo outerClass="w-60" />
  {#if processing}
    <h1 class="text-3xl">Authenticating</h1>
    <Loader />
  {:else}
    {#if loggedIn}
      <p class="text-xl mb-0">You are logged in 😊</p>
      <p>You can now close this window</p>
    {:else}
      <Button href="/">Please login to continue</Button>
    {/if}
  {/if}
</Layout>
