<script>
  import classNames from "classnames";
  import {extractData, extractViewBox} from "@/Lib/utils/svgData"

  export let data = '';
  export let viewBox = extractViewBox(data);
  export let focusable = "false";

  /** @type {import('@/Lib/types/component.d').SizeType} [size='md'] */
  export let size = 'md';

  $: display = extractData(data)
</script>

<svg
  {...$$restProps}
  class={classNames(size, $$props.class)} {focusable}
  role="img"
  {viewBox}
  xmlns="http://www.w3.org/2000/svg"
>
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  {@html display}
</svg>

<style lang="postcss">
  .xxs {
    @apply w-1.5 h-1.5;
  }

  .xs {
    @apply w-2.5 h-2.5;
  }

  .sm {
    @apply w-3.5 h-3.5;
  }

  .md {
    @apply w-4 h-4;
  }

  .lg {
    @apply w-5 h-5;
  }

  .xl {
    @apply w-6 h-6;
  }
  .xxl {
    @apply w-7 h-7;
  }
</style>
