<script>
  import Layout from "@/Layouts/Settings.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {page,  useForm} from "@inertiajs/svelte";
  import Button from "../../Components/Buttons/Button.svelte";
  import CKEditor from "@/Components/Forms/CKEditor.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconCopy, IconGoogle, IconMicrosoft} from "@/Components/Assets/Icons/index.js";
  import copy from "@/Lib/utils/copy.js";
  import {sendMessageToExtension} from "@/Lib/extension.js";
  import Textarea from "@/Components/Forms/Textarea.svelte";

  const form = useForm({
    auto_reply: $page.props?.reply ?? $page.props.auth.user.auto_reply ?? '',
    auto_reply_plain: $page.props?.reply_plain ?? $page.props.auth.user.auto_reply_plain ?? ''
  });

  function handleSave(event) {
    event.preventDefault();

    $form
      .transform((data) => ({
          auto_reply: data.auto_reply,
          auto_reply_plain: data.auto_reply_plain,
        }))
      .post('/app/settings/plugins/save', {
        onSuccess: () => {
          sendMessageToExtension('REPLY_UPDATED', {
            auto_reply: $page?.props?.auth?.user?.auto_reply,
            auto_reply_plain: $page?.props?.auth?.user?.auto_reply_plain
          });
        }
      });
  }
</script>

<Seo title="Gmail/Outlook Plugin Settings" />

<Layout title="Plugins" crumb="Plugins">
  <div class="mb-8 flex gap-2">
    <Button
      dynamic={false}
      class="!text-sm !gap-3"
      color="red"
      href="https://chromewebstore.google.com/detail/pitchfire/ipblfhcfgceibpobegdodamekfkeohdf"
      rel="noreferrer noopener"
      target="_blank"
    >
      <Icon size="lg" data={IconGoogle} class="fill-white" />
      <span>Google Chrome Extension</span>
    </Button>

    <Button
      dynamic={false}
      class="!text-sm !gap-3"
      color="red"
      href="https://workspace.google.com/marketplace/app/request_for_meeting/126639686392"
      rel="noreferrer noopener"
      target="_blank"
    >
      <Icon size="lg" data={IconGoogle} class="fill-white" />
      <span>Google Workspace</span>
    </Button>

    <Button
      dynamic={false}
      class="!text-sm"
      color="red"
      href="https://appsource.microsoft.com/en-GB/product/office/WA200006327"
      rel="noreferrer noopener"
      target="_blank"
    >
      <Icon size="lg" data={IconMicrosoft} class="fill-white" />
      <span>Microsoft Outlook</span>
    </Button>
  </div>

  <form method="post" on:submit={handleSave}>
    <fieldset>
      <h2>Email</h2>
      <p>Use the editor below to update the reply used for email</p>

      <CKEditor bind:value={$form.auto_reply} maxLength={2000} />

      {#if $form.errors.auto_reply}
        <p class="error">{$form.errors.auto_reply}</p>
      {/if}

      <button type="button" class="copy" on:click={copy(`${$page.props.common.app_url}/@${$page.props.auth?.user?.slug}`)}>
        <Icon data={IconCopy} />
        Copy Your Personal Link
      </button>
    </fieldset>

    <fieldset>
      <h2>LinkedIn</h2>
      <p>Use the editor below to update the automated reply used for LinkedIn</p>

      <div>
        <Textarea
          name="auto_reply_plain"
          size="sm"
          placeholder="Enter your automated reply for LinkedIn here"
          maxLength="2000"
          rows="10"
          bind:value={$form.auto_reply_plain}
          error={$form.errors.auto_reply_plain}
        />
      </div>

      {#if $form.errors.auto_reply}
        <p class="error">{$form.errors.auto_reply}</p>
      {/if}

      <button type="button" class="copy mt-4" on:click={copy(`${$page.props.common.app_url}/@${$page.props.auth?.user?.slug}`)}>
        <Icon data={IconCopy} />
        Copy Your Personal Link
      </button>
    </fieldset>

    <div class="actions">
      <Button size="sm" on:click={handleSave}>Save Changes</Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  fieldset {
    @apply mb-8;

    h2 {
      @apply text-xl mb-2;
    }

    p {
      @apply text-sm;

      &.error {
        @apply m-0 mt-1 text-red-600 leading-snug text-xs;
      }
    }
  }

  .copy {
    @apply flex gap-x-3 text-sm leading-6 hover:underline no-underline items-center;
  }

  .actions {
    @apply border-t border-grey-300 pt-8 text-right;
  }
</style>
