// Taken from https://github.com/themesberg/flowbite-svelte/blob/main/src/lib/utils/focusTrap.js

// Add all the elements inside modal which you want to make focusable
const selectorTabbable = `
  a[href], area[href], input:not([disabled]):not([tabindex='-1']),
  button:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),
  textarea:not([disabled]):not([tabindex='-1']),
  iframe, object, embed, *[tabindex]:not([tabindex='-1']):not([disabled]), *[contenteditable=true]
`;

/** @type {import('svelte/action').Action<HTMLElement, any>} */
export default function focusTrap(node) {
  /** @type {(e:KeyboardEvent)=>void} */
  function handleFocusTrap(event) {
    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    const tabbable = Array.from(node.querySelectorAll(selectorTabbable));

    let index = tabbable.indexOf(document.activeElement ?? node);
    if (index === -1 && event.shiftKey) {index = 0;}
    index += tabbable.length + (event.shiftKey ? -1 : 1);
    index %= tabbable.length;
    tabbable[index].focus();

    event.preventDefault();
  }

  document.addEventListener('keydown', handleFocusTrap, true);

  return {
    destroy() {
      document.removeEventListener('keydown', handleFocusTrap, true);
    }
  };
}
