<script>
  import classNames from 'classnames';

  /** @type {import('@/Lib/types/component.d').SelectOptionType[]} options */
  export let options;
  export let label = '';
  export let labelHtml = '';
  export let description = '';

  /** @type {string | number} value */
  export let value = '';

  export let placeholder = 'Choose...';

  /** @type {string} name */
  export let name;

  /** @type {boolean} disabled */
  export let disabled;
  export let loading = false;

  export let error = '';

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size;

  /** @type {string} inputClass */
  export let inputClass;

  /**
   * @typedef {'top' | 'inline'} Position
   */

  /** @satisfies Position */
  export let labelPosition;
</script>

<div class={classNames('input', size, disabled && 'disabled', loading && 'loading', labelPosition && `label-${labelPosition}`)}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name}>{label}{@html labelHtml}</label>
  {/if}

  <div>
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}
    <select {...$$restProps} bind:value class={inputClass} disabled={disabled || loading} {name} on:change on:contextmenu on:input>
      {#if placeholder}
        <option disabled selected value="">{placeholder}</option>
      {/if}

      {#if options !== undefined}
        {#each options as item}
          <option value={item.value} disabled={item.disabled} selected={item.selected}>{item.label}</option>
        {/each}
      {:else}
        <slot />
      {/if}
    </select>
    {#if loading}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    {/if}
    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700 min-w-[5rem];
    }

    & > div {
      @apply relative w-full items-center inline-flex;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    select {
      @apply border border-grey-200 rounded-lg px-2 py-2 text-grey-700 text-base block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 appearance-none bg-no-repeat bg-[97%_center] bg-contain bg-[length:10px_9px];
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.9 7.2'%3e%3cpath fill='%2399999B' d='M7.9 0H1C.4 0 0 .4 0 1c0 .2.1.4.2.6l3.5 5.2c.2.4.9.5 1.3.2.1 0 .2-.1.3-.2l3.5-5.2c.3-.5.2-1.1-.3-1.4-.2-.1-.4-.2-.6-.2z'/%3e%3c/svg%3e");
    }

    svg {
      @apply block w-4 h-4 animate-spin absolute z-10 right-4;
    }

    &.xs {
      label {
        @apply text-xs;
      }

      select {
        @apply py-1 text-xs;
      }
    }

    &.sm {
      label {
        @apply text-sm;
      }

      select {
        @apply py-1.5 text-xs;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }

      select {
        @apply py-3 text-lg;
      }
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
