<script>
  import Seo from "@/Components/Seo/Index.svelte";
  import Layout from "@/Layouts/Page.svelte";
  import Works from '@/Components/Sections/Works.svelte';
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import { inertia, page } from "@inertiajs/svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconLinkedIn, IconWWW} from "@/Components/Assets/Icons/index.js";
</script>

<Seo
  title={`Pitch ${$page.props?.organisation?.name} employees`}
  ogImage={{
    url: `${$page.props?.common.app_url  }/og-images/companies/${  $page.props?.organisation?.slug  }.png`,
    alt: $page.props?.organisation?.name,
  }}
/>

<Layout nav footer>
  <section class="profile">
    <div class="org">
      <div class="header">
        {#if $page.props?.organisation?.logo != null}
          <a class="logo" href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${$page.props?.organisation?.slug}` : `/org/${$page.props?.organisation?.slug}`} use:inertia>
            <img src={$page.props?.organisation?.logo} alt={$page.props?.organisation?.name} />
          </a>
        {/if}

        <div>
          <h1><a href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${$page.props?.organisation?.slug}` : `/org/${$page.props?.organisation?.slug}`} use:inertia>{$page.props?.organisation?.name}</a></h1>

          {#if $page.props?.organisation?.slogan != null}
            <p>{$page.props?.organisation?.slogan}</p>
          {/if}
        </div>
      </div>

      <div class="cols">
        <div class="col1">
          <h2>Overview</h2>

          {#if $page.props?.organisation?.domain != null}
            <div class="field">
              <a class="icon-link" href="https://{$page.props?.organisation?.domain}" target="_blank" rel="noopener noreferrer">
                <Icon data={IconWWW} size="xxl" />
                <span>{$page.props?.organisation?.domain}</span>
              </a>
            </div>
          {/if}

          {#if $page.props?.organisation?.linkedin_url}
            <div class="field">
              <a class="icon-link" href={$page.props?.organisation?.linkedin_url} target="_blank" rel="noopener noreferrer">
                <Icon data={IconLinkedIn} size="xxl" />
                <span>{$page.props?.organisation?.linkedin_url.replace('http://www.linkedin.com/company/', '')}</span>
              </a>
            </div>
          {/if}

          {#if $page.props?.tags && $page.props?.tags.length > 0}
            <div class="field">
              <h3>INDUSTRY</h3>
              <p>{$page.props?.tags.map((tag, index) => index === $page.props?.tags.length - 1 ? ` ${  tag.name}` : tag.name)}</p>
            </div>
          {/if}

          {#if $page.props?.organisation?.city || $page.props?.organisation?.country}
            <div class="field">
              <h3>HEADQUARTERS</h3>
              <p>{$page.props?.organisation?.city ? `${$page.props?.organisation?.city  }, ` : ""}{$page.props?.organisation?.country ? $page.props?.organisation?.country : ""}</p>
            </div>
          {/if}
        </div>

        <div class="col2">
          {#if $page.props?.organisation?.details}
            <h2>Details</h2>
            <div class="details">{$page.props?.organisation?.details}</div>
          {/if}
        </div>
      </div>
    </div>

    <div class="team-wrap">
      {#if $page.props?.organisation?.users?.length > 0}
        <div class="team">
          {#each $page.props?.organisation?.users as user}
            <div class="member">
              <a href={$page.props?.auth.loggedIn ? `/app/marketplace/@${user.slug}` : `/@${user.slug}`} use:inertia>
                <img src={user?.avatar} alt={user.name} />
              </a>
              <h4><a href={`/@${user.slug}`} use:inertia>{user.name}</a></h4>
              <p>{user?.job_level ?? '-'}</p>
              <a class="btn-red w-full" href={$page.props?.auth.loggedIn ? `/app/marketplace/@${user.slug}` : `/@${user.slug}`} use:inertia>Starting at ${user?.pitch_price ?? 25}</a>
            </div>
          {/each}
        </div>

        <div class="actions">
          <p>{$page.props?.organisation?.users?.length} {$page.props?.organisation?.users?.length === 1 ? 'Person works' : 'People work'} at {$page.props?.organisation?.name}</p>

          <!-- {#if $page.props?.organisation?.users?.length > 3} -->
          <!--  <button on:click={(event) => loadUsers(event)}>View all</button> -->
          <!-- {/if} -->
        </div>
      {/if}
    </div>
  </section>

  <section id="how-it-works" class="how">
    <Works title="How does Pitchfire work?" />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  .profile {
    @apply overflow-hidden rounded-xl;
  }

  .org {
    @apply bg-blue-100 py-5 md:py-6 px-6 lg:px-10;

    .header {
      @apply flex items-center mb-6;

      .logo {
        @apply mr-4;

        img {
          @apply w-20 h-20 rounded-full;
        }
      }

      h1 {
        @apply text-3xl font-semibold m-0 text-grey-800;

        a {
          @apply no-underline;
        }
      }

      p {
        @apply text-grey mt-2 text-sm;
      }
    }

    .cols {
      @apply flex w-full flex-col md:flex-row gap-8;

      .col1 {
        @apply w-full md:w-5/12;
      }

      .col2 {
        @apply w-full md:w-7/12;
      }
    }

    .icon-link {
      @apply flex items-center text-sm no-underline hover:underline;

      span {
        @apply ml-2 block;
      }
    }

    h1 {
      @apply text-2xl text-grey-800 font-semibold mb-5;
    }

    h2 {
      @apply font-semibold text-base leading-5 text-grey-800 mb-7;
    }

    .field {
      @apply mb-5;

      h3 {
        @apply text-xs text-black mb-1 uppercase;
      }

      p {
        @apply text-sm text-grey-800 font-normal mb-5;
      }
    }

    .details {
      @apply text-sm text-grey-800 font-normal mb-5 whitespace-pre-line;
    }
  }

  .team-wrap {
    @apply bg-blue-800 py-8 px-6 lg:px-10;

    .team {
      @apply grid items-center justify-center sm:justify-start gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
    }

    .member {
      @apply bg-blue-100 p-3 rounded-xl w-full text-left max-w-56 mx-auto;

      h4 {
        @apply text-lg font-semibold text-grey-800 mt-2;

        a {
          @apply no-underline;
        }
      }

      img {
        @apply w-full rounded-xl object-cover h-48;
      }
    }

    .actions {
      @apply mt-12;

      p {
        @apply text-white text-base font-semibold text-left;
      }
    }
  }

  .how {
    border-top: 3px solid;
    @apply mt-12 max-w-5xl lg:px-12 mx-auto pt-12 border-white;
  }
</style>
