<script>
  import Layout from '@/Layouts/Respond.svelte';
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import {IconGoogleColor, IconMicrosoftColor} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
</script>

<Seo title="Connect your calendar" />

<Layout nav>
  <div class="description">
    <p><strong>Pitchfire requires connecting your calendar in order to send or receive pitches</strong>.</p>
    <ul>
      <li>Connecting your calendar makes it easy to book a meeting should you be interested.</li>
      <li>If you submit a pitch to someone else, and they want to meet, this makes it one click.</li>
      <li>This allows us not to worry about calendar invites going to spam.</li>
      <li>Don’t worry, we’re not doing anything shady.. we don’t store, sell, or steal data from your calendar.</li>
      <li>Your super secret mysterious meetings are safe with us.</li>
    </ul>
  </div>

  <div class="items">
    {#if $page.props.auth.login_provider === 'google'}
      <div class="item">
        {#if $page.props?.googleConnected}
          <div>
            <Icon data={IconGoogleColor} size="xl" />
            <span>Google Calendar Connected</span>
            <span class="status"></span>
          </div>
        {:else}
          <a href={$page.props?.googleAuthUrl}>
            <Icon data={IconGoogleColor} size="xl" />
            <span>Connect your Google calendar</span>
            <span class="status"></span>
          </a>
        {/if}
      </div>
    {/if}

    {#if $page.props.auth.login_provider === 'microsoft'}
      <div class="item">
        {#if $page.props?.microsoftConnected}
          <div>
            <Icon data={IconMicrosoftColor} size="xl" />
            <span>Microsoft Calendar Connected</span>
            <span class="status"></span>
          </div>
        {:else}
          <a href={$page.props?.microsoftAuthUrl}>
            <Icon data={IconMicrosoftColor} size="xl" />
            <span>Connect your Microsoft calendar</span>
            <span class="status"></span>
          </a>
        {/if}
      </div>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .description {
    @apply pt-4 md:pt-8;

    p {
      @apply text-base mb-4;
    }

    li {
      list-style-type: "🔥";
    }
  }

  .items {
    @apply mt-4;
  }

  .item {
    @apply mt-4;

    a,
    div {
      @apply rounded-lg border border-blue p-3 justify-center font-semibold inline-flex gap-5 items-center text-blue text-base leading-none;
    }

    .status {
      @apply w-2 h-2 block rounded-full;
    }

    a {
      @apply cursor-pointer bg-white hover:bg-blue-50 hover:text-blue focus:bg-blue-50 focus:ring-2 focus:outline-none no-underline;

      .status {
        @apply bg-red-600;
      }
    }

    div {
      .status {
        @apply bg-green-600;
      }
    }
  }
</style>
