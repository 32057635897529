<script>
  import classNames from "classnames";
  import { inertia } from "@inertiajs/svelte";
  import {onMount} from "svelte";

  export let href = '';

  /** @type {string} image */
  export let image;

  /** @type {string} imageAlt */
  export let imageAlt;
  export let name = '';
  export let title = '';
  export let org_name = '';
  export let org_url = '';

  let isExternalImage = false;

  onMount(() => {
    const isExternalURL = (url) => new URL(url).origin !== location.origin;
    if (image && isExternalURL(image)) {
      isExternalImage = true;
    }
  });
</script>

<div class={classNames($$props.class, 'p-3 bg-blue-100 rounded-xl flex flex-col', (title || $$slots) && 'with-body')}>
  <div>
    {#if image}
      {#if href}
        <a {href} class="block rounded-xl overflow-hidden mb-4" use:inertia>
          <img class="object-cover w-full h-auto aspect-square" src={image} alt={imageAlt} referrerpolicy={isExternalImage ? 'no-referrer' : 'origin'} />
        </a>
      {:else}
        <img class="block rounded-xl overflow-hidden mb-4 object-cover" src={image} alt={imageAlt} referrerpolicy={isExternalImage ? 'no-referrer' : 'origin'} />
      {/if}
    {/if}

    {#if name || $$slots}
      {#if name}
        {#if href}
          <a {href} class="block mb-2 text-lg font-semibold text-left no-underline leading-tight" use:inertia>{name}</a>
        {:else}
          <div class="mb-2 text-base font-semibold text-left leading-tight">{name}</div>
        {/if}
      {/if}

      {#if title}
        <div class="mb-2 text-grey-600 text-sm text-left leading-tight">{title}</div>
      {/if}

      {#if org_name}
        {#if org_url}
          <a href={org_url} class="block text-grey-600 font-bold text-sm text-left leading-tight no-underline" use:inertia>{org_name}</a>
        {:else}
          <div class="text-grey-600 text-sm text-left leading-tight">{org_name}</div>
        {/if}
      {/if}

      <slot />
    {/if}
  </div>

  {#if $$slots.actions}
    <div class="mt-auto">
      <slot name="actions" />
    </div>
  {/if}
</div>
