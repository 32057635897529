<script>
  import Layout from "@/Layouts/Settings.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconBin, IconClose, IconTick} from "@/Components/Assets/Icons/index.js";
  import {success} from "@/Lib/notices.js";

  let confirmAccountDeletion = false,
   formEmailAdd = useForm({ email: '' }),
   formEmailDelete = useForm({ email: '' }),
   formAccountDelete = useForm({}),
   formPersona = useForm({
    persona: $page.props?.auth?.user?.persona
  }),

   emailFormEnabled = false,
   emailPending = false,

   emailsToRemove = [];

  const addInput = () => {
    emailFormEnabled = true;
  },

   removeEmail = (email) => {
    if (!emailsToRemove.includes(email)) {
      emailsToRemove = [...emailsToRemove, email];
    }
  },

   cancelRemoveEmail = (email) => {
    if (emailsToRemove.includes(email)) {
      emailsToRemove = emailsToRemove.filter(e => e !== email);
      $formEmailDelete.reset();
      $formEmailDelete.clearErrors();
    }
  },

   handleEmailRemove = (email) => {
    $formEmailDelete.transform(() => ({ email })).post('/app/settings/account/delete');
  },

   handleEmailAdd = () => {
    $formEmailAdd.post('/app/settings/account/save', {
      preserveState: true,
      onSuccess: () => {
        emailPending = true;
        success('Verification email has been sent, please check your inbox.')
      }
    });
  },

   handlePersonaSubmit = (event) => {
    event.preventDefault();
    $formPersona.post('/app/settings/account/persona');
  },

   handleAccountDelete = (event) => {
    event.preventDefault();
    confirmAccountDeletion = true;
  },

   handleConfirmAccountDelete = (event) => {
    event.preventDefault();
    $formAccountDelete.post('/app/settings/account/destroy');
  },

   canDelete = $page.props?.organisations?.filter(o => o.members > 1).length < 1;
</script>

<Seo title="Account Settings" />

<Layout title="Account Settings" crumb="Account">
  <form on:submit={(e) => handlePersonaSubmit(e)} class="flex justify-between flex-col">
    <fieldset>
      <h2>What will you use Pitchfire for?</h2>
      <div class="mt-6 space-y-2">
        <div class="flex items-center">
          <input id="buyer" name="persona" type="radio" value="Buyer" bind:group={$formPersona.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="buyer" class="ml-3 block text-sm font-medium leading-6 text-grey-900">Get way less emails</label>
        </div>
        <div class="flex items-center">
          <input id="seller" name="persona" type="radio" value="Seller" bind:group={$formPersona.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="seller" class="ml-3 block text-sm font-medium leading-6 text-grey-900">Actually get responses from prospects</label>
        </div>
        <div class="flex items-center">
          <input id="both" name="persona" type="radio" value="Both" bind:group={$formPersona.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="both" class="ml-3 block text-sm font-medium leading-6 text-grey-900">I want all the things!</label>
        </div>
      </div>
    </fieldset>

    {#if $formPersona.errors.persona}
      <p class="m-0 mt-1 text-red-600 leading-snug text-xs">{$formPersona.errors.persona}</p>
    {/if}

    <div class="text-start mt-4">
      <Button color="tertiary" size="xs" on:click={(event) => handlePersonaSubmit(event)}>Save</Button>
    </div>
  </form>

  <h2 class="mt-8 border-t border-grey-200 pt-8">Alternative Emails</h2>
  <p>These are the email addresses associated with your main Pitchfire account.</p>
  <p>You can use these email addresses to log in to your account or to receive notifications.</p>
  <p>The Pitchfire plugins can also be used from any email accounts you add here.</p>

  <div class="emails">
    <div class="email-item">
      <div>{$page.props.auth.user?.email}</div>
      <span>Primary</span>
    </div>

    {#each $page.props?.emails as item}
      <div class="email-item">
        <div>{item.email}</div>

        {#if !emailsToRemove.includes(item.email)}
          <button class="delete" on:click|preventDefault={() => removeEmail(item.email)}>
            <Icon data={IconBin} />
            <span>remove</span>
          </button>

          {#if item.status === false}
            <button class="verify" on:click|preventDefault={() => handleEmailAdd(item.email)}>verify email</button>
          {/if}
        {:else}
          <button class="cancel" on:click|preventDefault={() => cancelRemoveEmail(item.email)}>
            <Icon data={IconClose} />
            <span>remove</span>
          </button>
          <button class="confirm" on:click|preventDefault={() => handleEmailRemove(item.email)}>
            <Icon data={IconTick} />
            <span>are you sure?</span>
          </button>
        {/if}

        {#if $formEmailDelete.errors.email}
          <p class="error">{$formEmailDelete.errors.email}</p>
        {/if}
      </div>
    {/each}

    {#if emailFormEnabled}
      {#if emailPending}
        <div class="verify">A verification email has been sent to the email address you added. <button on:click={() => handleEmailAdd()}>Resend</button></div>
      {:else}
        <form method="post" class="add" on:submit|preventDefault={() => handleEmailAdd()}>
          <div>
            <input size="sm" type="email" placeholder="Add email address" bind:value={$formEmailAdd.email} />

            {#if $formEmailAdd.errors.email}
              <p class="error">{$formEmailAdd.errors.email}</p>
            {/if}
          </div>
          <Button size="xs" color="secondary" on:click={() => handleEmailAdd()}>Add</Button>
        </form>
      {/if}
    {:else}
      <Button color="tertiary" size="xs" on:click={() => addInput()}>Add Email</Button>
    {/if}
  </div>

  <form method="post" on:submit={handleAccountDelete} class="mt-8 border-t border-grey-200 pt-8">
    <fieldset>
      <h2>Delete Account</h2>
      <p>Deleting your account will remove all your data.</p>

      {#if $page.props?.organisations?.filter(o => o.members > 1).length > 1}
        <p>Your account is currently an owner in these organizations:</p>
        <ul class="list-disc ml-4">
          {#each $page.props?.organisations.filter(o => o.members > 1) as organisation}
            <li class="text-sm my-0"><strong>{organisation?.name}</strong></li>
          {/each}
        </ul>
        <p>You must transfer ownership before you can delete your account, please <a href="mailto:support@pitchfire.com">contact support</a>.</p>
      {/if}

      {#if confirmAccountDeletion}
        <div class="bg-red-50 px-4 py-2 mb-4 border-red-600 border rounded inline-block">
          <p class="my-0">Are you sure you want to delete your account?</p>
        </div>
        <div class="flex gap-4 mb-4">
          <Button size="xs" color="red" on:click={handleConfirmAccountDelete} disabled={!canDelete} loading={$formAccountDelete.processing}>Yes, delete account</Button>
          <Button size="xs" on:click={() => confirmAccountDeletion = false} loading={$formAccountDelete.processing}>No, cancel</Button>
        </div>
      {:else}
        <Button size="xs" color="red" on:click={handleAccountDelete} disabled={!canDelete}>Delete your account</Button>
      {/if}
    </fieldset>
  </form>
</Layout>

<style lang="postcss">
  h2 {
    @apply text-xl mb-2;
  }

  p {
    @apply text-sm;
  }

  .emails {
    @apply flex items-start flex-col gap-4;
  }

  .email-item {
    @apply flex items-center gap-4 text-xs sm:text-sm max-w-sm w-full justify-between;

    div {
      @apply border border-grey-200 rounded-lg px-2 py-2 text-sm !leading-none block cursor-not-allowed text-grey-800 bg-grey-100 w-full overflow-x-auto;
    }

    button {
      @apply flex gap-2 items-center;

      &.delete,
      &.cancel {
        @apply fill-red text-red-600;
      }

      &.confirm {
        @apply fill-green text-green-600;
      }

      span {
        @apply text-xs text-nowrap sr-only;
      }
    }

    p.error {

    }
  }

  .add {
    @apply flex items-center gap-4 text-xs sm:text-sm max-w-sm w-full justify-between;

    & > div {
      @apply w-full;
    }

    input {
      @apply border border-grey-200 rounded-lg px-2 py-2 text-grey-700 text-sm block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500;
    }
  }

  .verify {
    @apply text-sm text-green-600;

    button {
      @apply underline;
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }
</style>
