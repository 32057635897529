<script>
  import Layout from "@/Layouts/App.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import ImageUpload from "@/Components/Forms/ImageUpload.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";
  import InputTags from "@/Components/Forms/InputTags.svelte";
  import { page, useForm } from "@inertiajs/svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import { IconPencil } from "@/Components/Assets/Icons/index.js";
  import api from "@/Lib/api.js";
  import {failure} from "@/Lib/notices.js";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import {onMount} from "svelte";
  import {sendMessageToExtension} from "@/Lib/extension.js";

  const form = useForm({
    avatar: "",
    name: $page.props?.auth?.user?.name,
    job_title: $page.props?.auth?.user?.job_title,
    management_level: $page.props?.management_level,
    department: $page.props?.departments?.value || "", // Ensure to set a default value if department is undefined
    focuses: $page.props?.focuses,
    timezone: $page.props.auth?.user?.timezone,
    vendors: $page.props?.auth?.user?.vendors,
    linkedin: $page.props?.auth?.user?.linkedin_url,
    country: $page.props?.country?.value,
    state: $page.props?.state?.value,
    city: $page.props?.auth?.user?.city,
    about: $page.props?.auth?.user?.about || "",
    preferred_employment: $page.props?.auth?.user?.preferred_employment,
  });

  let processing = false;

  onMount(() => {
    $form.timezone = $page.props.auth?.user?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    initializeForm();
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    $form
      .transform((data) => {
        const timezone = typeof data.timezone === 'string' ? data.timezone : data.timezone.value,
         focuses = data.focuses.map((x) => x.value),
         selectedCountry = $page.props.countries.find(
          (country) => country.id === data.country,
        ),
         countryName = selectedCountry ? selectedCountry.country_name : '',
         selectedState = states?.find((state) => state.id === data?.state) ?? '',
         stateName = selectedState ? selectedState.state_name : '';

        return {
          avatar: data.avatar,
          name: data.name,
          job_title: data.job_title,
          management_level: data.management_level,
          linkedin: data.linkedin,
          timezone,
          country: data.country,
          countryName,
          state: data.state,
          stateName,
          city: data.city,
          preferred_employment: data.preferred_employment,
          about: data.about,
          vendors: data.vendors,
          departments: data.department,
          focuses,
        };
      })
      .post('/app/profile/save', {
        onSuccess: (response) => {
          sendMessageToExtension('PROFILE_UPDATED', {
            name: response?.props?.auth?.user?.name,
            avatar: response?.props?.auth?.user?.avatar,
            email: response?.props?.auth?.user?.email,
            url: response?.props?.auth?.user?.url
          });
        }
      });
  },

   fetchStatesByCountry = async (countryId) => {
    processing = true;
    return await api(`/app/profile/get-states/${countryId}`)
      .then((res) => res.data)
      .then((res) => {
        processing = false;

        if (res?.states?.length < 1) {
          $form.state = '';
        }

        return res.states;
      })
      .catch((e) => {
        processing = false;
        failure(e.message)
      });
  }

  let states = [];
  async function handleCountryChange(event) {
    const selectedCountryId = event.target.value;
    states = await fetchStatesByCountry(selectedCountryId);
  }

  let userCountry = null;
  async function initializeForm() {
    userCountry = $page.props.countries.find(
      (country) => country.country_name === $page.props.auth.user.country,
    );

    if (userCountry) {
      $form.country = userCountry.id;
      states = await fetchStatesByCountry(userCountry.id);
      const userState = states.find(
        (state) => state.state_name === $page.props.auth.user.state,
      );
      $form.state = userState ? userState.id : null;
    }
  }
</script>

<Seo title="Update your Information" />

<Layout>
  <section>
    <Breadcrumb
      crumbs={[
        { title: "Menu", url: "/" },
        { title: "Update your Information" },
      ]}
    />

    <form method="post" on:submit|preventDefault={handleSubmit}>
      <div class="avatar">
        <ImageUpload
          name="avatar"
          preview={$page.props.auth?.user?.avatar}
          bind:value={$form.avatar}
        />

        {#if $form.errors.avatar}
          <div class="!mt-1 text-red-600 text-sm">{$form.errors.avatar}</div>
        {/if}

        <div class="ml-3">
          <div class="name-input">
            <input type="text" name="name" bind:value={$form.name} />
            <Icon data={IconPencil} />
          </div>

          {#if $form.errors.name}
            <div class="!mt-1 text-red-600 text-sm">{$form.errors.name}</div>
          {/if}
        </div>
      </div>

      <div class="profile">
        <div class="profile--info">
          <div class="field">
            <h5>Company</h5>
            <p>{$page.props.auth?.org?.name}</p>
          </div>

          <div class="field">
            <label for="job_title">Job Title</label>

            <Input
              inputClass="!bg-white !text-sm !rounded min-h-10"
              type="text"
              class="sm"
              name="job_title"
              placeholder="Enter job title"
              bind:value={$form.job_title}
              error={$form.errors.job_title}
            />
          </div>

          <div class="field">
            <label for="management_level">My Seniority <sup>*</sup></label>

            <Select
              inputClass="!bg-white !text-sm !rounded min-h-10"
              name="management_level"
              placeholder="select management level"
              required
              disabled={$form.processing}
              bind:value={$form.management_level}
              options={$page.props.options?.management_levels}
              error={$form.errors.management_level}
            />
          </div>

          <div class="field">
            <label for="departments">My Department <sup>*</sup></label>

            <Select
              inputClass="!bg-white !text-sm !rounded min-h-10"
              name="departments"
              placeholder="select department"
              required
              disabled={$form.processing}
              bind:value={$form.department}
              options={$page.props.options?.departments}
              error={$form.errors.departments}
            />
          </div>

          <div class="field">
            <label for="focuses">Focused on <sup>*</sup></label>

            <InputTags
              name="focuses"
              inputClass="!bg-white !text-sm !rounded min-h-10"
              size="sm"
              defaultTags={$page.props.focuses}
              suggestionTags={$page.props.options?.focuses}
              bind:value={$form.focuses}
              placeholder="Type to search or add tag"
              isTag
            />

            {#if $form.errors.focuses}
              <div class="error">{$form.errors.focuses}</div>
            {/if}
          </div>

          <div class="field">
            <label for="vendors">Vendors we use</label>

            <Input
              inputClass="!bg-white !text-sm !rounded min-h-10"
              type="text"
              class="sm"
              name="vendors"
              placeholder="Enter vendor"
              bind:value={$form.vendors}
              error={$form.errors.vendors}
            />
          </div>
        </div>

        <div class="profile--info">
          <div class="field">
            <label for="linkedin">LinkedIn Profile</label>

            <Input
              type="url"
              name="linkedin"
              inputClass="!bg-white !text-sm !rounded min-h-10"
              inputOuterClass="linkedin-outer"
              placeholder="Paste your LinkedIn URL"
              bind:value={$form.linkedin}
              error={$form.errors.linkedin}
            />
          </div>

          <div class="inputs">
            <div class="field">
              <label for="country">Country <sup>*</sup></label>
              <Select
                inputClass="!bg-white !text-sm !rounded min-h-10"
                name="country"
                placeholder="Select country"
                required
                disabled={$form.processing}
                bind:value={$form.country}
                options={$page.props.countries
                  ? $page.props.countries.map((country) => ({
                      value: country.id,
                      label: country.country_name,
                      countryName: country.country_name,
                    }))
                  : []}
                on:change={handleCountryChange}
                error={$form.errors.country}
              />
            </div>

            <div class="field">
              <label for="state">State</label>
              <Select
                inputClass="!bg-white !text-sm !rounded min-h-10"
                name="state"
                placeholder="Select state"
                disabled={$form.processing || processing || states.length < 1}
                bind:value={$form.state}
                options={states?.map((state) => ({
                  value: state.id,
                  label: state.state_name,
                }))}
                error={$form.errors.states}
              />
            </div>
          </div>

          <div class="inputs">
            <div class="field">
              <label for="city">City</label>
              <Input
                inputClass="!bg-white !text-sm !rounded min-h-10"
                type="text"
                class="sm"
                name="city"
                placeholder="Enter you Town/City"
                bind:value={$form.city}
                error={$form.errors.city}
              />
            </div>
          </div>

          <div class="inputs">
            <div class="field">
              <label for="timezone">Timezone <sup>*</sup></label>
              <SelectBox
                id="timezone"
                name="timezone"
                size="sm"
                inputClass="!bg-white !text-sm !rounded min-h-10"
                multiple={false}
                placeholder="Select Timezone"
                options={$page.props?.timezones}
                bind:value={$form.timezone}
                error={$form.errors.timezone}
              />
            </div>
          </div>

          <div class="field">
            <label for="preferred_employment">How I Like to Buy Products</label>

            <Input
              inputClass="!bg-white !text-sm !rounded min-h-10"
              type="text"
              class="sm"
              name="preferred_employment"
              placeholder="What do you look for in a product or service?"
              bind:value={$form.preferred_employment}
              error={$form.errors.preferred_employment}
            />
          </div>

          <div class="field">
            <label for="about">About Me</label>

            <Textarea
              inputClass="!bg-white !text-sm !rounded"
              class="sm"
              name="about"
              placeholder="What do you look for in a product or service?"
              bind:value={$form.about}
              error={$form.errors.about}
            />
          </div>
        </div>
      </div>

      <div class="actions">
        <Button
          color="secondary"
          class="mr-4"
          type="submit"
          size="sm"
          loading={$form.processing}
          on:submit={(event) => handleSubmit(event)}
        >
          Save Changes
        </Button>

        <Button color="tertiary" size="sm" href="/app/profile" dynamic
          >Cancel</Button
        >
      </div>
    </form>
  </section>
</Layout>

<style lang="postcss">
  section {
    @apply px-7 pt-7 pb-36 relative bg-grey-100 min-h-[100vh];

    form {
      @apply mt-10;

      .avatar {
        @apply flex flex-wrap items-center gap-4 mb-6 overflow-hidden;

        .name-input {
          @apply flex items-center mt-2.5 md:mt-0 py-1.5 px-2 bg-white;

          input {
            @apply text-3xl text-grey-800 font-semibold w-full;
          }
        }
      }
    }

    .profile {
      @apply grid md:grid-cols-2 md:gap-8 max-w-6xl;

      .profile--info {
        @apply w-full;
      }

      .inputs {
        @apply flex md:gap-8 flex-col md:flex-row items-start;
      }

      .field {
        @apply w-full mt-6;

        h5,
        label {
          @apply text-xs mb-1.5 uppercase antialiased font-display font-semibold block;

          sup {
            @apply text-red-500 text-base top-0 ml-1 leading-none;
          }
        }

        p {
          @apply m-0 text-sm leading-none md:min-h-11 md:flex md:items-center;
        }
      }

      .error {
        @apply m-0 mt-1 text-red-600 leading-snug text-xs;
      }
    }

    .actions {
      @apply fixed w-full py-5 px-7 lg:left-80 left-0 bottom-0 bg-white z-10 shadow-pf-ac;
    }
  }
</style>
