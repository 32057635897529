<script>
  import Layout from "@/Layouts/Respond.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Modal from "@/Components/Elements/Modal.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Label from "@/Components/Forms/Label.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";

  let modalActive = true,

   form = useForm({
    reason: $page.props.response?.reason ?? '',
    response_body: $page.props.response?.body ?? ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    $form.post(`/app/respond/p/${$page.props.pitch.id}/respond/save`, {
      onSuccess: () => {
        $form.reset();
        modalActive = false;
      }
    })
  }

  $: followUpQuestion = $page.props?.questions && $form.reason ? $page.props?.questions[$form.reason] : null;
  $: responseAllowed = $form.reason && $form.response_body.length > 20;
</script>

<Seo title={`Review $${$page.props?.pitch?.request_fee} Pitch from ${$page.props?.pitch?.seller_name}`} />

<Layout>
  <div class="pitch">
    <h4>Review Pitch</h4>
    <div class="body">{$page.props?.pitch.body}</div>
  </div>

  <div class="actions-wrap">
    <div class="actions">
      <Button color="tertiary" href="/app/respond/p/{$page.props?.pitch.id}" dynamic>Cancel</Button>
      <Button color="secondary" on:click={() => modalActive = true}>Respond</Button>

      <Modal open={modalActive} permanent class="!top-24 md:top-0">
        <div class="decline-modal">
          <h4>How do you want to respond to {$page.props?.pitch.seller_name}</h4>
          <p>Try and give {$page.props?.pitch.seller_name} their
            <span>${$page.props?.pitch.request_fee} worth</span> 😊</p>

          <form method="post" on:submit={(event) => handleSubmit(event)}>
            <div>
              <Select name="reason" label="Response" size="sm"
                      placeholder="Select a response" options={$page.props.responses}
                      bind:value={$form.reason}
                      error={$form.errors.reason} />
            </div>

            {#if followUpQuestion}
              <div>
                <Label name="response_body" size="sm" label={followUpQuestion} />
                <Textarea name="response_body" size="sm" placeholder="Answer here..."
                          maxLength="1000" rows="6" bind:value={$form.response_body}
                          error={$form.errors.response_body} />
              </div>
            {/if}

            <div class="actions">
              <Button color="tertiary" type="button" size="sm" href="/app/respond" dynamic>Cancel</Button>
              <Button color="secondary"
                      disabled={!responseAllowed}
                      loading={$form.processing}
                      on:click={(event) => handleSubmit(event)}>Respond</Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>

    {#if $page.props?.pitch.is_paid}
      <p>You get paid <span>${$page.props?.pitch.request_fee} either way</span> 😊</p>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply flex flex-col relative mb-4;

    h4 {
      @apply text-base mb-4;
    }

    .body {
      @apply whitespace-pre-line border p-4 rounded-xl;
    }
  }

  .actions-wrap {
    @apply mt-4 mb-16 flex flex-col items-end;

    & > .actions {
      @apply mb-2;
    }

    & > p {
      @apply m-0;
    }
  }

  p {
    span {
      @apply text-violet font-semibold;
    }
  }

  .decline-modal {
    h4 {
      @apply text-xl mb-4;
    }

    p {
      @apply text-sm mb-4;
    }

    form {
      @apply flex flex-col gap-4;

      .actions {
        @apply flex gap-4 justify-end;
      }
    }
  }
</style>
