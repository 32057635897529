<script>
  import LayoutApp from "@/Layouts/App.svelte"
  import LayoutResponse from "@/Layouts/Response.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import YouTube from "@/Components/Elements/YouTube.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import {page} from "@inertiajs/svelte";
</script>

<Seo title="Responses" />

{#if $page.props?.total > 0}
  <LayoutResponse />
{:else}
  <LayoutApp>
    <div class="content">
      <h1>Responses</h1>
      <Breadcrumb crumbs={[
        { title: 'Menu', url: '/' },
        { title: 'Responses' }
      ]} />

      <div>
        <YouTube id="J2c9UAKPBpU" />
      </div>
    </div>
  </LayoutApp>
{/if}

<style lang="postcss">
  .content {
    @apply py-8 lg:py-6 px-6 lg:px-8;

    h1 {
      @apply text-3xl md:text-4xl mb-3;
    }

    div {
      @apply w-full py-8 lg:py-10 text-center flex items-center justify-center flex-col max-w-6xl;
    }
  }
</style>
