<script>
  import Select from "svelte-select";
  import {IconChevron, IconClose} from "@/Components/Assets/Icons/index";
  import Icon from "@/Components/Assets/Icon.svelte";
  import classNames from "classnames";

  export let options;
  export let label = '';
  export let labelHtml = '';
  export let description = '';
  export let required = false;
  export let multiple = true;
  export let value;
  export let placeholder = 'Choose...';

  /** @type {string} name */
  export let name;

  /** @type {string} id */
  export let id = name;

  /** @type {boolean} disabled */
  export let disabled;
  export let loading = false;

  export let error = '';

  /**
   * @typedef {'xxs' | 'xs' | 'sm' | 'md' | 'lg'} SelectSize
   * @typedef {'top' | 'inline'} SelectPosition
   */

  /** @satisfies SelectSize */
  export let size = 'sm';

  /** @satisfies SelectPosition */
  export let labelPosition = 'top';
</script>

<div class={classNames('input', size, disabled && 'disabled', loading && 'loading', `label-${labelPosition}`)}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name} id={`${name}-label`}>{label}{@html labelHtml}</label>
  {/if}

  <div>
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}
    <Select
      --border="1px solid #dedee0"
      --border-radius="0.5rem"
      --chevron-height="30px"
      --chevron-width="30px"
      --font-size="0.75rem"
      --height="auto"
      --input-color="#3d3d40"
      --internal-padding="0.25rem"
      --item-height="auto"
      --item-hover-bg="#EBECFB"
      --item-line-height="1"
      --item-padding="0.5rem 0.5rem"
      --list-position="fixed"
      --multi-item-height="1.25rem"
      --multi-select-input-margin="0"
      --multi-select-padding="0 0 0 0.5rem"
      --value-container-padding="0.325rem 0"
      bind:value
      class="leading-none"
      {disabled}
      {id}
      itemId="value"
      items={options}
      {loading}
      {multiple}
      {name}
      on:change
      on:clear
      on:select
      {placeholder}
      {required}
      showChevron
    >
      <svelte:fragment slot="chevron-icon">
        <Icon class="fill-grey-500" data={IconChevron} size="xs" />
      </svelte:fragment>
      <svelte:fragment slot="clear-icon">
        <Icon class="fill-grey-800" data={IconClose} size="sm" />
      </svelte:fragment>
      <div class="clear-icon" slot="multi-clear-icon">
        <Icon class="fill-white" data={IconClose} size="xs" />
      </div>
    </Select>

    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700 min-w-[5rem];
    }

    & > div {
      @apply relative w-full items-center inline-flex;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    &.xs {
      label {
        @apply text-sm;
      }
    }

    &.sm {
      label {
        @apply text-sm;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  .item {
    @apply flex gap-2 items-center justify-start;
  }

  .clear-icon {
    @apply rounded-full p-0.5 bg-grey-400;
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
