<script>
  import { useForm } from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Layout from "@/Layouts/Auth.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  const form = useForm({
    company: '',
  }),

   handleSubmit = async () => {
    $form.post('/app/onboarding/organization/save');
  };
</script>

<Seo title="Tell us about yourself" />

<Layout title="Tell us about yourself" page={2}>
  <form method="post" on:submit|preventDefault={handleSubmit}>
    <div>
      <label for="company">Company Name <sup>*</sup></label>
      <Input
        inputClass="!bg-white !text-sm !rounded min-h-10"
        type="text"
        name="company"
        autocomplete="organization"
        bind:value={$form.company}
        placeholder="Enter your company name"
        error={$form.errors.company}
        required
        size="sm"
      />
    </div>

    <Button
      type="submit"
      size="sm"
      class="max-w-28"
      loading={$form.processing}
      on:click={handleSubmit}>Next</Button>
  </form>

  <div class="notes">
    <p>Businesses pitching using Pitchfire are interested in knowing where you work.</p>
  </div>
</Layout>

<style lang="postcss">
  form {
    @apply flex justify-between flex-col;

    & > div {
      @apply mb-4;
    }

    label {
      @apply text-xs uppercase;

      sup {
        @apply text-red-600 text-sm;
      }
    }
  }

  .notes {
    @apply text-xs mt-4;

    p {
      @apply mb-2;
    }
  }
</style>
