export const meta = (key) =>{

  let retryCount = 0;

  if (! key) {
    console.error('Meta key is required');
    return;
  }

  const loadScript = () => {
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) {return;}
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) {f._fbq = n;}
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
  },

   initialise = () => {
    window.fbq('init', key);
    window.fbq('track', 'PageView');
  },

   retryMetaInit = () => {
    if (typeof window.fbq === 'function') {
      initialise();
      clearInterval(retryMeta);
      return;
    }

    if (retryCount > 10) {
      clearInterval(retryMeta);
      return;
    }

    retryCount++;
  },

   retryMeta = setInterval(retryMetaInit, 1000);

  loadScript();
}
