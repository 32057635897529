<script>
  import classNames from "classnames";

  export let href = '';
  export let title = '';
  export let subtitle = '';
</script>

<svelte:element class={classNames($$props.class, 'card')} this={href ? 'a' : 'div'}>
  {#if title}
    <div class="header">
      <div class="title">{title}</div>
      {#if subtitle}
        <div class="subtitle">{subtitle}</div>
      {/if}
    </div>
  {/if}

  <div class="body">
    <slot />
  </div>
</svelte:element>

<style lang="postcss">
  a.card {
    @apply cursor-pointer no-underline;

    &:hover {
      @apply shadow-none;
    }
  }

  .card {
    @apply border bg-white border-grey-200 shadow-lg rounded-2xl;
  }

  .header {
    @apply bg-grey-100 border-b border-grey-200 py-6 px-10 rounded-t-2xl;

    .title {
      @apply text-lg font-semibold line-clamp-1;
    }

    .subtitle {
      @apply text-xs text-grey-500 mt-2 leading-none line-clamp-1;
    }
  }

  .body {
    @apply py-8 px-6;
  }
</style>
