<script>
  import {onMount} from "svelte";
  import {scale} from "svelte/transition";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconPlay} from "@/Components/Assets/Icons";

  export let id;

  let title = "",
   width = 0,
   height = 0,

   videoInfo = {};
  onMount(async () => {
    const res = await fetch(
      `//www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}&format=json`
    );
    videoInfo = await res.json();
    title = videoInfo?.title;
    width = videoInfo?.width;
    height = videoInfo?.height;
  });

  let play = false;
  const isCustomThumbnail = $$slots.thumbnail,
    handlePlay = () => {
      play = true;
    }
</script>

<div class="youtube" style:--aspect-ratio={width / height || '16/9'} {title}>
  {#if play}
    <iframe
      {title}
      src="https://www.youtube.com/embed/{id}?autoplay=1&rel=0"
      frameborder="0"
      allow="autoplay; picture-in-picture; clipboard-write"
      allowfullscreen
      in:scale={{ delay: 500, duration: 800 }}></iframe>
  {:else}
    {#if isCustomThumbnail}
      <slot name="thumbnail" />
    {:else}
      <img src="https://i.ytimg.com/vi/{id}/maxresdefault.jpg" {id} {play} alt="" referrerpolicy="no-referrer" />
    {/if}

    <div
      class="overlay"
      role="presentation"
      on:click={handlePlay}
      on:keypress={handlePlay}></div>
  {/if}

  {#if !play}
    <button on:click={handlePlay}>
      <Icon data={IconPlay} class="fill-red !w-12 !h-12" />
    </button>
  {/if}
</div>

<style lang="postcss">
  .youtube {
    @apply relative aspect-[1.76991] overflow-hidden flex justify-center w-full;

    &:hover {
      .overlay {
        @apply bg-black/50;
      }
    }

    iframe {
      @apply h-auto w-full aspect-[var(--aspect-ratio)]
    }

    button {
      @apply absolute z-10 p-0 top-0 bottom-0;
    }
  }

  .overlay {
    @apply absolute inset-0 w-full cursor-pointer aspect-[var(--aspect-ratio)];
    transition: all 250ms ease-in-out;
  }
</style>
