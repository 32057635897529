<script>
  import classNames from 'classnames';

  export let label = '';
  export let labelHtml = '';
  export let description = '';

  /** @type {string} name */
  export let name;

  /** @type {boolean} disabled */
  export let disabled;

  /** @type {string} inputClass */
  export let inputClass;

  /** @type {string} errorClass */
  export let errorClass = '';

  /** @type {string | number} value */
  export let value = '';
  export let loading = false;

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size;

  /**
   * @typedef {'top' | 'inline'} Position
   */

  /** @satisfies Position */
  export let labelPosition = 'top';
  export let maxLength = 255;

  export let error = '';

  $: hasError = value?.length > maxLength || error;
  $: rows = ((value?.match(/\n/g) || []).length + 1 < 6) ? 6 : (value?.match(/\n/g) || []).length + 3;
</script>

<div class={classNames('input', size, `label-${labelPosition}`, hasError && 'error')}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name}>{label}{@html labelHtml}</label>
  {/if}

  <div>
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}
    <slot props={{ ...$$restProps, class: inputClass, disabled: disabled || loading, name }}>
      <textarea
        {...$$restProps}
        bind:value
        class={inputClass}
        disabled={disabled || loading}
        {name}
        on:blur
        on:change
        on:click
        on:contextmenu
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        on:mouseenter
        on:mouseleave
        on:mouseover
        on:paste
        {rows}></textarea>

      {#if maxLength}
        <div class={classNames('counter', !value || value?.length <= maxLength ? '' : 'error')}>
          <span>{value?.length ?? 0} / {maxLength}</span>
        </div>
      {/if}
      {#if loading}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      {/if}
    </slot>
    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
  </div>

  {#if error}
    <p class={`error ${errorClass}`}>{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700;
    }

    & > div {
      @apply relative w-full items-center inline-flex;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    &.error {
      textarea {
        @apply border-red border-2 focus:border-red;
      }

      label,
      .counter {
        @apply text-red;
      }
    }

    textarea {
      @apply border border-grey-200 rounded-lg px-2 py-3 text-grey-700 text-base !leading-tight block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500 resize-none box-border;
    }

    .counter {
      @apply text-xs text-right absolute bottom-1.5 right-1.5 text-grey-500 bg-white/80 py-0.5 px-1;
    }

    svg {
      @apply block w-4 h-4 animate-spin absolute z-10 right-4;
    }

    &.xs {
      label {
        @apply text-xs;
      }

      textarea {
        @apply py-2 text-xs;
      }
    }

    &.sm {
      label {
        @apply text-sm;
      }

      textarea {
        @apply py-2 text-sm;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }

      textarea {
        @apply py-3.5 text-lg;
      }
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
