<script>
  import { onMount } from 'svelte';
  import classNames from "classnames";
  import Loader from "@/Components/Elements/Loader.svelte";

  export let action;
  export let cData;
  export let response = null;
  export let theme = 'light';

  let element = null,
   innerWidth = 0;

  export let error = '';

  const resetResponse = () => {
    response = null;

    // Reset the widget after 1 second to prevent the user from spamming the button
    setTimeout(() => turnstile?.reset(element), 1000);
  }

  let turnstile;

  onMount(() => {
    turnstile = window.turnstile;
  });

  let isLoaded = false;

  const turnstileCallback = async () => {
    turnstile = await window.turnstile;
    isLoaded = true;
  }

  $: isMobile = innerWidth < 768;

  // $: if (turnstile && !isLoaded) turnstile.ready(() => {
  //   IsLoaded = true
  // });

  $: if (turnstile && isLoaded) {
    turnstile.render(element, {
      sitekey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
      action,
      cData,
      theme,
      size: isMobile ? 'compact' : 'normal',
      callback: (token) => (response = token),
      'expired-callback': resetResponse,
      'timeout-callback': resetResponse,
      'error-callback': resetResponse,
    });
  }

  $: if (error) {
    resetResponse();
  }
</script>

<svelte:window bind:innerWidth />

<svelte:head>
  <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer on:load={() => turnstileCallback()}></script>
</svelte:head>

<div class={classNames('cf', isMobile ? 'compact' : 'normal')} bind:this={element}>
  {#if !isLoaded}<Loader />{/if}
</div>

{#if error}
  <p class="error">{error}</p>
{/if}

<style lang="postcss">
  .cf {
    @apply mt-4;
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  :global(.cf.normal iframe) {
    @apply !w-full;
  }
</style>
