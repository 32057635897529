<script>
  import Layout from '@/Layouts/Page.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import TagGroup from "@/Components/Elements/TagGroup.svelte";
</script>

<Seo title="Review Pitch" />

<Layout nav footer>
  <div class="wrap">
    <div class="book">
      <div class="header">
        <Profile
          title={`Hi! I'm ${$page.props?.profile?.name}`}
          titleElement="h1"
          subTitleHtml={$page.props?.profile?.description}
          subTitleElement="h2"
          image={$page.props?.profile?.avatar}
          imageAlt={$page.props?.profile?.name}
          subImage={$page.props?.profile?.org_logo}
          subImageAlt={$page.props?.profile?.org_name}
          size="xl"
        >
          {#if $page.props.focuses?.length > 0}
            <TagGroup tags={$page.props.focuses.map(x => ({ title: x.name }))} />
          {/if}

          <div slot="actions" class="amount">
            ${$page.props?.campaign_link?.amount}
          </div>
        </Profile>
      </div>

      <div class="pitch">
        <h4>Review my pitch.</h4>

        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <div class="body">{@html $page.props?.campaign_link?.pitch}</div>

        <div class="actions-wrap">
          <div class="actions">
            <Button color="secondary" href="/app/respond/c/{$page.props?.campaign_link?.id}/respond" dynamic>Respond</Button>
          </div>

          <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
        </div>
      </div>
    </div>

    <img src="/assets/calendar-bg.png" alt="" role="presentation" />
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply relative mb-20 min-h-full;

    & > img {
      @apply blur-sm sm:absolute right-0 top-32;
    }
  }

  .book {
    @apply border bg-white border-grey-200 shadow-lg rounded-2xl max-w-4xl mx-auto relative z-10;
  }

  .header {
    @apply bg-grey-100 border-b border-grey-200 px-6 py-4 md:px-10 md:py-8 rounded-t-2xl;

    .amount {
      @apply text-violet text-2xl font-semibold mb-2;
    }
  }

  .pitch {
    @apply px-6 py-4 md:px-10 md:py-8;

    h4 {
      @apply font-semibold inline-block leading-snug text-sm;
    }

    .body {
      @apply border border-grey-200 rounded-lg p-4 text-grey-700 text-base my-4;
    }

    .actions-wrap {
      @apply mt-4 flex flex-col items-end;

      & > .actions {
        @apply mb-2;
      }

      & > p {
        @apply m-0;

        span {
          @apply text-violet font-semibold;
        }
      }
    }
  }
</style>
