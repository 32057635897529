<script>
  import Layout from "@/Layouts/Upload.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import cn from "classnames";

  const form = useForm({}),
  handleSubmit = (event) => {
    event.preventDefault();
    $form.post(`/app/events/${$page.props?.import?.id}/upload/import/process`);
  },

   mappings = [];
  for (const key in $page.props?.import?.mappings) {
    mappings.push({name: key, label: $page.props?.import?.mappings[key]});
  }
</script>

<Layout
  title={`${$page.props?.conference?.name  } - Import`}
  conference={$page.props?.conference}
  conferenceImport={$page.props?.import}
>
  <h2 class="block text-lg mt-8 mb-4">Confirm Import Details</h2>

  <h3 class="block text-base mt-8 mb-4">Mappings</h3>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
     {#each mappings as mapping}
      <div class="flex items-center text-sm">
        <div class="w-1/2 font-bold">{mapping?.name}:</div>
        <div class={cn("w-1/2", mapping?.label ? 'text-green-600' : 'text-red-600')}>{mapping?.label ?? 'skipped'}</div>
      </div>
     {/each}
  </div>

  <h3 class="block text-base mt-8 mb-4">Estimated records to import: {$page.props?.import?.rows}</h3>

  <form on:submit={(e) => handleSubmit(e)}>
    <div class="mt-12">
      <Button type="submit" on:click={(e) => handleSubmit(e)} size="sm" color="secondary">Start Import</Button>
    </div>
  </form>
</Layout>
