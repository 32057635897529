<script>
  import Seo from "@/Components/Seo/Index.svelte";
  import Layout from "@/Layouts/App.svelte";
  import Works from '@/Components/Sections/Works.svelte';
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import { inertia, page } from "@inertiajs/svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconLinkedIn, IconWWW} from "@/Components/Assets/Icons/index.js";

  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import MarketplaceResult from "@/Components/Elements/MarketplaceResult.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
</script>

<Seo
  title={`Pitch ${$page.props?.organisation?.name} employees`}
  ogImage={{
    url: `${$page.props?.common.app_url  }/og-images/companies/${  $page.props?.organisation?.slug  }.png`,
    alt: $page.props?.organisation?.name
  }}
/>

<Layout>
  <section class="profile">
    <Breadcrumb
      crumbs={[
        { title: "Menu", url: "/" },
        { title: "Buyer Marketplace", url: "/app/marketplace" },
        { title: $page.props?.organisation?.name },
      ]}
    />

    <div class="org">
      <div class="header">
        {#if $page.props?.organisation?.logo != null}
          <a class="logo" href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${$page.props?.organisation?.slug}` : `/org/${$page.props?.organisation?.slug}`} use:inertia>
            <img src={$page.props?.organisation?.logo} alt={$page.props?.organisation?.name} />
          </a>
        {/if}

        <div>
          <h1><a href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${$page.props?.organisation?.slug}` : `/org/${$page.props?.organisation?.slug}`} use:inertia>{$page.props?.organisation?.name}</a></h1>

          {#if $page.props?.organisation?.slogan != null}
            <p>{$page.props?.organisation?.slogan}</p>
          {/if}
        </div>
      </div>

      <div class="cols">
        <div class="col1">
          <h2>Overview</h2>

          {#if $page.props?.organisation?.domain != null}
            <div class="field">
              <a class="icon-link" href="https://{$page.props?.organisation?.domain}" target="_blank" rel="noopener noreferrer">
                <Icon data={IconWWW} size="xxl" />
                <span>{$page.props?.organisation?.domain}</span>
              </a>
            </div>
          {/if}

          {#if $page.props?.organisation?.linkedin_url}
            <div class="field">
              <a class="icon-link" href={$page.props?.organisation?.linkedin_url} target="_blank" rel="noopener noreferrer">
                <Icon data={IconLinkedIn} size="xxl" />
                <span>{$page.props?.organisation?.linkedin_url.replace('http://www.linkedin.com/company/', '')}</span>
              </a>
            </div>
          {/if}

          {#if $page.props?.tags && $page.props?.tags.length > 0}
            <div class="field">
              <h3>INDUSTRY</h3>
              <p>{$page.props?.tags.map((tag, index) => index === $page.props?.tags.length - 1 ? ` ${  tag.name}` : tag.name)}</p>
            </div>
          {/if}

          {#if $page.props?.organisation?.city || $page.props?.organisation?.country}
            <div class="field">
              <h3>HEADQUARTERS</h3>
              <p>{$page.props?.organisation?.city ? `${$page.props?.organisation?.city  }, ` : ""}{$page.props?.organisation?.country ? $page.props?.organisation?.country : ""}</p>
            </div>
          {/if}
        </div>

        <div class="col2">
          {#if $page.props?.organisation?.details}
            <h2>Details</h2>
            <div class="details">{$page.props?.organisation?.details}</div>
          {/if}
        </div>
      </div>
    </div>
  </section>

  {#if $page.props?.members?.length > 0}
    <section class="team-wrap">
      <div class="team">
        {#each $page.props?.members as member}
          <MarketplaceResult href={`/app/marketplace/@${member.data?.slug}`}
             name={member?.data?.name}
             image={member?.data?.avatar ?? ''}
             imageAlt={member.data?.name}
             title={member.data?.job_title ?? member.data?.job_level}
             org_name={member.data?.org_name}
             org_url={`/app/marketplace/org/${member.data?.org_slug}`}
             price={member.data?.pitch_price ?? 25}
             theme="minimal"
             size="xl"
          >
            <div slot="actions" class="text-center mt-4">
              <Button dynamic size="sm" color="red" class="w-full" href={`/app/marketplace/@${member.data?.slug}`}>Starting at ${member?.data?.pitch_price ?? 25}</Button>
            </div>
          </MarketplaceResult>
        {/each}
      </div>

      <div class="actions">
        {#if $page.props?.organisation?.logo}
          <img class="w-10 block" src={$page.props?.organisation?.logo} alt={$page.props?.organisation?.name}>
        {/if}

        <p>{$page.props?.member_count} {$page.props?.member_count === 1 ? 'buyer' : 'buyers'} at {$page.props?.organisation?.name}</p>

        <!-- {#if $page.props?.organisation?.users?.length > 3} -->
        <!--  <button on:click={(event) => loadUsers(event)}>View all</button> -->
        <!-- {/if} -->
      </div>
    </section>
  {/if}

  <section id="how-it-works" class="how">
    <Works title="How does Pitchfire work?" />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  section {
    @apply py-2.5 px-4 lg:px-8 mb-12 max-w-7xl;
  }

  .profile {
    @apply overflow-hidden rounded-xl;
  }

  .org {
    @apply mt-4;

    .header {
      @apply flex items-center mb-6;

      .logo {
        @apply mr-4;

        img {
          @apply w-20 h-20 rounded-full;
        }
      }

      h1 {
        @apply text-3xl font-semibold m-0 text-grey-800;

        a {
          @apply no-underline;
        }
      }

      p {
        @apply text-grey mt-2 text-sm;
      }
    }

    .cols {
      @apply flex w-full flex-col md:flex-row gap-8;

      .col1 {
        @apply w-full md:w-5/12;
      }

      .col2 {
        @apply w-full md:w-7/12;
      }
    }

    .icon-link {
      @apply flex items-center text-sm no-underline hover:underline;

      span {
        @apply ml-2 block;
      }
    }

    h1 {
      @apply text-2xl text-grey-800 font-semibold mb-5;
    }

    h2 {
      @apply font-semibold text-base leading-5 text-grey-800 mb-7;
    }

    .field {
      @apply mb-5;

      h3 {
        @apply text-xs text-black mb-1 uppercase;
      }

      p {
        @apply text-sm text-grey-800 font-normal mb-5;
      }
    }

    .details {
      @apply text-sm text-grey-800 font-normal mb-5 whitespace-pre-line;
    }
  }

  .team-wrap {
    .team {
      @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4;
    }

    .actions {
      @apply mt-12 flex gap-4 items-center;

      p {
        @apply text-base font-semibold text-left m-0;
      }
    }
  }
</style>
