<script>
  import Layout from "@/Layouts/App.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import { page } from "@inertiajs/svelte";
  import SectionProfile from "@/Components/Sections/Profile.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
</script>

<Seo title="Profile" />

<Layout>
  <section>
    <Breadcrumb
      class="px-5 md:px-7"
      crumbs={[
        { title: "Menu", url: "/" },
        { title: "Update your Information" },
      ]}
    />

    <SectionProfile profile={$page.props?.profile} class="mt-10 mb-5" />

    <div class="actions">
      <Button color="secondary" class="!px-10" size="sm" href="/app/profile/edit" dynamic>Edit</Button>
    </div>
  </section>
</Layout>

<style lang="postcss">
  section {
    @apply min-h-[100vh] pt-7 pb-20 relative bg-grad-tertiary;
  }

  .actions {
    @apply fixed w-full py-5 px-5 md:px-7 lg:left-80 left-0 bottom-0 bg-white z-10 shadow-pf-ac;
  }
</style>
