import {failure, success} from "../notices";

/** @param {string} text */
export default async function copy(text) {
  try {
    await navigator.clipboard.writeText(text);
    success('Successfully copied to clipboard')
  } catch (err) {
    failure('Failed to copy to clipboard')
    console.error('Failed to copy: ', err);
  }
}
