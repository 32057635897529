<script>
  import classNames from 'classnames';

  export let label = '';
  export let labelHtml = '';
  export let subLabel = '';
  export let description = '';

  /** @type {string} name */
  export let name;

  /** @type {boolean} disabled */
  export let disabled;

  /** @type {string} inputClass */
  export let inputClass;

  /** @type {number} value */
  export let value;
  export let valuePrefix = '';

  /**
   * @typedef {'text-left' | 'text-center' | 'text-right'} RangePosition
   */

  /** @satisfies RangePosition */
  export let valuePosition;
  export let minLabel = '';
  export let maxLabel = '';
  export let loading = false;

  export let error = '';

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size = 'sm';

  /**
   * @typedef {'top' | 'inline'} Position
   */

  /** @satisfies Position */
  export let labelPosition = 'top';
</script>

<div class={classNames('input', size, $$props.class, `label-${labelPosition}`)}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name}>{label}{@html labelHtml}</label>
  {/if}

  {#if subLabel}
    <p class="sub">{subLabel}</p>
  {/if}

  <div>
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}

    <div class={classNames('wrap', minLabel || maxLabel ? 'has-labels' : '', )}>
      <div class={classNames('val', valuePosition)}>{valuePrefix ?? ''}{value}</div>

      {#if minLabel}
        <span class="min">{minLabel}</span>
      {/if}

      <input
        {...$$restProps}
        bind:value
        class={inputClass}
        disabled={disabled || loading}
        {name}
        on:change
        on:click
        on:keydown
        on:keypress
        on:keyup
        step="5"
        type="range" />

      {#if maxLabel}
        <span class="max">{maxLabel}</span>
      {/if}

      {#if loading}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      {/if}
    </div>

    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700;
    }

    p.sub {
      @apply text-xs leading-tight mt-2.5 text-grey-600;
    }

    & > div {
      @apply relative w-full items-center inline-flex;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    .wrap {
      @apply relative w-full;

      &.has-labels {
        @apply pb-8;
      }
    }

    .val {
      @apply text-violet text-2xl font-semibold;
    }

    .min,
    .max {
      @apply absolute text-sm;
    }

    .min {
      @apply left-0 bottom-0;
    }

    .max {
      @apply right-0 bottom-0;
    }

    input {
      @apply h-2 w-full bg-grey-200 cursor-pointer accent-violet;

      &::-webkit-slider-thumb {
        @apply ring-4 ring-violet-500/10 rounded-full;
      }
    }

    svg {
      @apply block w-4 h-4 animate-spin absolute z-10 right-4;
    }

    &.sm {
      label {
        @apply text-sm;
      }

      input {
        @apply h-1;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }

      input {
        @apply h-3;
      }
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
