<script>
  import Layout from "@/Layouts/Auth.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Input from "@/Components/Forms/Input.svelte";

  const form = useForm({
    job_title: '',
    management_level: '',
    departments: []
  }),

   handleSubmit = () => {
    $form
      .transform(data => {
        const departments = data?.departments?.map(x => x.value);
        return {
          job_title: data?.job_title,
          management_level: data?.management_level,
          departments
        };
      })
      .post('/app/onboarding/details/save');
  };
</script>

<Seo title="Tell us about yourself" />

<Layout title="Tell us about yourself" page={4}>
  <form method="post" on:submit|preventDefault={handleSubmit}>
    <div>
      <label for="job_title">My Job Title <sup>*</sup></label>
      <Input
        name="job_title"
        placeholder="Enter your job title"
        required
        size="sm"
        disabled={$form.processing}
        bind:value={$form.job_title}
        bind:error={$form.errors.job_title}
      />
    </div>

    <div>
      <label for="management_level">My Seniority <sup>*</sup></label>
      <Select
        name="management_level"
        placeholder="Select a management level"
        required
        size="sm"
        options={$page.props?.management_levels}
        disabled={$form.processing}
        bind:value={$form.management_level}
        bind:error={$form.errors.management_level}
      />
    </div>

    <div>
      <label for="departments">My Department <sup>*</sup></label>
      <SelectBox
        name="departments"
        placeholder="Select department(s)"
        required
        options={$page.props?.departments}
        disabled={$form.processing}
        bind:value={$form.departments}
        bind:error={$form.errors.departments}
      />
    </div>

    <Button
      type="submit"
      size="sm"
      class="max-w-28"
      loading={$form.processing}
      on:click={handleSubmit}>Next</Button>
  </form>

  <div class="notes">
    <p>This will help you come up more in searches in our marketplace.</p>
  </div>
</Layout>

<style lang="postcss">
  form {
    @apply flex justify-between flex-col;

    & > div {
      @apply mb-4;
    }

    label {
      @apply text-xs uppercase;

      sup {
        @apply text-red-600 text-sm;
      }
    }
  }

  .notes {
    @apply text-xs mt-4;

    p {
      @apply mb-2;
    }
  }
</style>
