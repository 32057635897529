<script>
  import classNames from 'classnames';
  import {getContext} from 'svelte';

  export let href = '';
  export let title = '';

  /** @type {import('@/Lib/types/component.d').TagSizeType} size */
  export let size = 'sm';

  const group = getContext('group');
</script>

<svelte:element
  {...$$restProps}
  class={classNames($$props.class, group ?? size, group && 'grouped')}
  {href}
  on:click
  role="button"
  tabindex="0"
  this={href ? 'a' : 'div'}
>
  <slot>{title}</slot>
</svelte:element>

<style lang="postcss">
  a, div {
    @apply rounded py-1 px-1.5 bg-white border border-grey-200 text-grey-600 inline-flex items-center justify-start gap-1 text-xs whitespace-nowrap leading-none;

    &.md {
      @apply py-1.5 px-2 text-sm;
    }

    &.lg {
      @apply py-2 px-2.5 text-base;
    }
  }

  a {
    @apply hover:bg-blue hover:text-white focus:ring-blue-300 focus:ring-2 focus:outline-none cursor-pointer no-underline;

    &:hover {
      @apply fill-white
    }
  }
</style>
