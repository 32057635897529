<script>
  import Layout from "./Default.svelte";
  import cn from "classnames";
</script>

<Layout>
  <div class="outer">
    <div class={cn('wrap', $$props.class)}>
      <slot />
    </div>
  </div>
</Layout>

<style lang="postcss">
  .outer {
    @apply w-full bg-to-top-grad-gray md:h-screen;

    .wrap {
      @apply flex flex-col mx-auto lg:container md:h-screen items-center justify-center;
    }
  }
</style>
