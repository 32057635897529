const buildDescription = (title = '', organisation = '', department = '', focuses = false) => {
  let description = '';

  if (title) {
    description += title;
  }

  if (organisation) {
    if (department) {
      description += ` working in <strong>${department}</strong>`;
      description += ` at <strong>${organisation}</strong>`;
    } else {
      description += ` working at <strong>${organisation}</strong>`;
    }
  } else if (department) {
      description += ` working in <strong>${department}</strong>`;
    }

  if (focuses) {
    description += ` working on`;
  }

  return description;
}

export {
  buildDescription
}
